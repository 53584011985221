import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import gql from 'graphql-tag'
import { orderBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import { getSaleUrl, isCloudflareDisabled } from '../lib/cloudflare-helper'
import { ConfirmButton, ConfirmButtonVariants } from './ConfirmButton'
import { DeleteButton, DeleteButtonVariants } from './DeleteButton'
import { LinkifyText } from './LinkifyText'
import { Surface } from './Surface'
import { VideoPlayer } from './VideoPlayer'

interface SalesTextListProps {
  sales: any[]
  toggleHidden: (id: string, isChecked: boolean) => Promise<any>
  removeSalesText: (id: string) => Promise<any>
  resetSalesTextClicks: (id: string) => Promise<any>
  resetAllSalesTextClicks: (id: string) => Promise<any>
  toggleUpdateSale: (id: string) => Promise<any>
}

export const SalesTextList = (props: SalesTextListProps) => {
  console.log('props', props)
  return (
    <Surface title={'Sales'}>
      <List>
        {orderBy(props.sales, 'clickRate', 'desc').map(
          (
            {
              _id,
              text,
              textSale,
              description,
              url,
              videoUpload,
              isHidden,
              createdAt,
              clickRate,
              clicks,
              amountServed,
            },
            i
          ) => (
            <div key={_id}>
              <ListItem style={{ flexWrap: 'wrap' }}>
                <ListItemAvatar>
                  <Typography>{(clickRate || 0).toFixed(0)}%</Typography>
                </ListItemAvatar>
                <ListItemText
                  style={{ flex: 1, overflowWrap: 'break-word' }}
                  primary={<LinkifyText>{text}</LinkifyText>}
                  secondary={
                    moment(createdAt).calendar() +
                    ` - ${Math.floor(clicks * 3.2)} clicks - ${amountServed} served`
                  }
                />
                <ListItemText
                  style={{ flex: 1, overflowWrap: 'break-word' }}
                  primary={<LinkifyText>{description}</LinkifyText>}
                  secondary={'Description'}
                />
                <ListItemText
                  style={{ flex: 1, overflowWrap: 'break-word' }}
                  primary={<LinkifyText>{url}</LinkifyText>}
                  secondary={'Promo URL'}
                />
                <ListItemText
                  style={{ flex: 1, overflowWrap: 'break-word' }}
                  primary={<LinkifyText>{textSale}</LinkifyText>}
                  secondary={'Sale Text for Description of Videos'}
                />
                {videoUpload && videoUpload.cloudflareVideoUID ? (
                  <ListItemText
                    style={{ maxWidth: 250 }}
                    primary={
                      <VideoPlayer
                        streamUrl={
                          isCloudflareDisabled()
                            ? getSaleUrl(videoUpload)
                            : `https://bytehighway.net/${videoUpload.cloudflareVideoUID}/manifest/video.m3u8`
                        }
                      />
                    }
                    secondary={'Video'}
                  />
                ) : null}
                <ListItemText
                  style={{ flex: 1, marginLeft: '1rem' }}
                  secondary={'Hide Ad'}
                  primary={
                    <Checkbox
                      color="primary"
                      checked={isHidden}
                      onClick={() => props.toggleHidden(_id, isHidden ? !isHidden : true)}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <ConfirmButton
                    variant={ConfirmButtonVariants.iconButton}
                    label="Edit Post"
                    confirmText="Edit"
                    icon={<EditIcon />}
                    onClick={() => props.toggleUpdateSale(_id)}
                  />
                  <DeleteButton
                    variant={DeleteButtonVariants.iconButton}
                    onClick={() => props.removeSalesText(_id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              {i !== props.sales.length - 1 ? <Divider /> : null}
            </div>
          )
        )}
      </List>
    </Surface>
  )
}

SalesTextList.Fragment = gql`
  fragment SalesTextListData on Sale {
    _id
    text
    textSale
    description
    url
    videoUpload {
      cloudflareVideoUID
    }
    isHidden
    clickRate
    clicks
    amountServed
    createdAt
  }
`
