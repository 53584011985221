export function isCloudflareDisabled() {
  return process.env.REACT_APP_CLOUDFLARE_DISABLED === 'true'
}

export function isBasicUrl(string: string) {
  return /^(https?:\/\/)/.test(string)
}

export function getSaleUrl(getSaleUrl: { cloudflareVideoUID: string; key: string }) {
  const { cloudflareVideoUID, key } = getSaleUrl
  if (isBasicUrl(cloudflareVideoUID)) {
    return cloudflareVideoUID
  }
  return `${process.env.REACT_APP_IMAGE_URL}/${key}`
}
