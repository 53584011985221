import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from '@apollo/react-hooks'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any
}

/** Action type for admins */
export enum ActionType {
  CreateChannel = 'createChannel',
  UpdateChannelPlaylistOrder = 'updateChannelPlaylistOrder',
  UpdateChannel = 'updateChannel',
  RemoveChannelFeaturedVideo = 'removeChannelFeaturedVideo',
  RemoveChannelLiveStreamVideo = 'removeChannelLiveStreamVideo',
  RemoveChannel = 'removeChannel',
  CreateConfig = 'createConfig',
  UpdateConfig = 'updateConfig',
  CreatePlaylist = 'createPlaylist',
  UpdatePlaylist = 'updatePlaylist',
  RemovePlaylist = 'removePlaylist',
  AddVideosToPlaylist = 'addVideosToPlaylist',
  RemoveVideosFromPlaylist = 'removeVideosFromPlaylist',
  CreateSale = 'createSale',
  ResetSaleClicks = 'resetSaleClicks',
  ResetAllSaleClicks = 'resetAllSaleClicks',
  CreateTag = 'createTag',
  CreateVideo = 'createVideo',
  UpdateVideo = 'updateVideo',
  RemoveVideo = 'removeVideo',
  ApproveComment = 'approveComment',
  RejectComment = 'rejectComment',
  BanUser = 'banUser',
  UnbanUser = 'unbanUser',
}

export type AdminAction = {
  __typename?: 'AdminAction'
  _id: Scalars['ID']
  adminUser: AdminUser
  type: ActionType
  video?: Maybe<Video>
  channel?: Maybe<Channel>
  playlist?: Maybe<Playlist>
  config?: Maybe<Config>
  tag?: Maybe<Tag>
  title?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type AdminUser = {
  __typename?: 'AdminUser'
  _id: Scalars['ID']
  email: Scalars['String']
  password?: Maybe<Scalars['String']>
  isAdmin?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  displayName?: Maybe<Scalars['String']>
  roles: Array<Roles>
  collaboratingChannels?: Maybe<Array<Maybe<Channel>>>
  suspended?: Maybe<Scalars['Boolean']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  playlists: Array<Playlist>
  videos: Array<Video>
  channels: Array<Channel>
}

export type AdminUserPlaylistsArgs = {
  public?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type AdminUserVideosArgs = {
  includeLive?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type AdminUserChannelsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type AuthenticateParamsInput = {
  access_token?: Maybe<Scalars['String']>
  access_token_secret?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  user?: Maybe<UserInput>
  code?: Maybe<Scalars['String']>
}

export type Channel = {
  __typename?: 'Channel'
  _id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  textInfo?: Maybe<Scalars['String']>
  featuredVideo?: Maybe<Video>
  liveStreamVideo?: Maybe<Video>
  alwaysLive?: Maybe<Scalars['Boolean']>
  dayTimesLive?: Maybe<DayTimesLive>
  isLive?: Maybe<Scalars['Boolean']>
  avatar?: Maybe<Scalars['String']>
  coverImage?: Maybe<Scalars['String']>
  showInfo?: Maybe<ShowInfo>
  creator?: Maybe<AdminUser>
  totalVideoViews?: Maybe<Scalars['Float']>
  totalLikes: Scalars['Float']
  playlists?: Maybe<Array<Playlist>>
  playlistOrder?: Maybe<Array<Maybe<Scalars['String']>>>
  hotVideos: Array<Video>
  totalVideos?: Maybe<Scalars['Float']>
  totalPlaylists?: Maybe<Scalars['Float']>
  links?: Maybe<ChannelLinks>
  lastUpload?: Maybe<Scalars['DateTime']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  videos: Array<Video>
}

export type ChannelHotVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type ChannelVideosArgs = {
  includeUnlisted?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  includeLive?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type ChannelInput = {
  title: Scalars['String']
  summary: Scalars['String']
  avatar: Scalars['String']
  coverImage: Scalars['String']
}

export type ChannelLinks = {
  __typename?: 'ChannelLinks'
  website?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  gab?: Maybe<Scalars['String']>
  minds?: Maybe<Scalars['String']>
  subscribeStar?: Maybe<Scalars['String']>
  telegram?: Maybe<Scalars['String']>
}

export type ChannelLinksInput = {
  website?: Maybe<Scalars['String']>
  facebook?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  gab?: Maybe<Scalars['String']>
  minds?: Maybe<Scalars['String']>
  subscribeStar?: Maybe<Scalars['String']>
  telegram?: Maybe<Scalars['String']>
}

export type Comment = {
  __typename?: 'Comment'
  _id: Scalars['ID']
  content: Scalars['String']
  user: User
  video?: Maybe<Video>
  replyTo?: Maybe<Comment>
  linkedUser?: Maybe<User>
  reported: Scalars['Boolean']
  approved: Scalars['Boolean']
  rejected: Scalars['Boolean']
  spam: Scalars['Boolean']
  duplicate?: Maybe<Scalars['Boolean']>
  bannedFromComment?: Maybe<Scalars['Boolean']>
  rejectedByUploader?: Maybe<Scalars['Boolean']>
  reports: Array<Report>
  reqHeaders: ReqHeaders
  replies?: Maybe<Array<Comment>>
  voteCount: VoteCount
  liked: Scalars['Boolean']
  replyCount: Scalars['Float']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type CommentRepliesArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type CommentInput = {
  content: Scalars['String']
  videoId: Scalars['String']
  replyToId?: Maybe<Scalars['String']>
  linkedUserId?: Maybe<Scalars['String']>
}

export type Config = {
  __typename?: 'Config'
  _id: Scalars['ID']
  title: Scalars['String']
  creator: AdminUser
  featuredVideos: Array<Video>
  navigationChannels?: Maybe<Array<Maybe<Channel>>>
  isActive: Scalars['Boolean']
  headerSaleActive: Scalars['Boolean']
  liveChannelId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type ConfigInput = {
  title: Scalars['String']
  isActive: Scalars['Boolean']
}

export type ContributorSignup = {
  __typename?: 'ContributorSignup'
  _id: Scalars['ID']
  fullName: Scalars['String']
  email: Scalars['String']
  twitter: Scalars['String']
  instagram: Scalars['String']
  youtube: Scalars['String']
  otherSocialProfiles: Scalars['String']
  video1: Scalars['String']
  video2: Scalars['String']
  user: User
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type CreateUserInput = {
  username?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  recaptchaToken: Scalars['String']
}

export type DayTimesLive = {
  __typename?: 'DayTimesLive'
  sun: Array<Scalars['String']>
  mon: Array<Scalars['String']>
  tue: Array<Scalars['String']>
  wed: Array<Scalars['String']>
  thu: Array<Scalars['String']>
  fri: Array<Scalars['String']>
  sat: Array<Scalars['String']>
}

export type DayTimesLiveInput = {
  sun: Array<Scalars['String']>
  mon: Array<Scalars['String']>
  tue: Array<Scalars['String']>
  wed: Array<Scalars['String']>
  thu: Array<Scalars['String']>
  fri: Array<Scalars['String']>
  sat: Array<Scalars['String']>
}

export type EmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings'
  disableNewUpload: Scalars['Boolean']
}

export type EmailRecord = {
  __typename?: 'EmailRecord'
  address?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export type HomeLayout = {
  __typename?: 'HomeLayout'
  _id: Scalars['ID']
  title: Scalars['String']
  creator: AdminUser
  featuredVideos: Array<Video>
  navigationChannels: Array<Channel>
  isActive: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type HomeLayoutInput = {
  title: Scalars['String']
  isActive: Scalars['Boolean']
}

export type ImpersonateReturn = {
  __typename?: 'ImpersonateReturn'
  authorized?: Maybe<Scalars['Boolean']>
  tokens?: Maybe<Tokens>
  user?: Maybe<User>
}

export type LiveInfo = {
  __typename?: 'LiveInfo'
  ingestURL: Scalars['String']
  ingestUser: Scalars['String']
  ingestPassword: Scalars['String']
  streamKey: Scalars['String']
}

export type LoginResult = {
  __typename?: 'LoginResult'
  sessionId?: Maybe<Scalars['String']>
  tokens?: Maybe<Tokens>
}

export type Mutation = {
  __typename?: 'Mutation'
  createUser?: Maybe<Scalars['ID']>
  verifyEmail?: Maybe<Scalars['Boolean']>
  resetPassword?: Maybe<LoginResult>
  sendVerificationEmail?: Maybe<Scalars['Boolean']>
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>
  changePassword?: Maybe<Scalars['Boolean']>
  twoFactorSet?: Maybe<Scalars['Boolean']>
  twoFactorUnset?: Maybe<Scalars['Boolean']>
  impersonate?: Maybe<ImpersonateReturn>
  refreshTokens?: Maybe<LoginResult>
  logout?: Maybe<Scalars['Boolean']>
  authenticate?: Maybe<LoginResult>
  loginAdmin: Scalars['String']
  signUpAdminUser: AdminUser
  resetAdminUserPassword: Scalars['Boolean']
  resetPasswordAdmin: Scalars['String']
  resetPasswordAdminToken: Scalars['String']
  forgotPasswordAdmin: Scalars['Boolean']
  setAdminUserPermissions: AdminUser
  suspendAdminUser: Scalars['Boolean']
  createChannel: Channel
  updateChannelPlaylistsOrder: Channel
  updateChannel: Channel
  removeChannelFeaturedVideo: Channel
  removeChannelLiveStreamVideo: Channel
  removeChannel: Scalars['Boolean']
  createComment: Comment
  approveComment: Comment
  rejectComment: Comment
  reportComment: Scalars['Boolean']
  removeComment: Scalars['Boolean']
  createConfig: Config
  updateConfig: Config
  submitContributorSignup: Scalars['Boolean']
  createHomeLayout: HomeLayout
  updateHomeLayout: HomeLayout
  setNotificationOpened: Notification
  setNotificationsSeen: Array<Notification>
  setPushNotificationReceived: Scalars['Boolean']
  setPushNotificationOpened: Scalars['Boolean']
  createPlaylist: Playlist
  updatePlaylist: Playlist
  removePlaylist: Scalars['Boolean']
  addVideosToPlaylist: Playlist
  removeVideosFromPlaylist: Playlist
  createSale: Sale
  updateSale: Sale
  resetSaleClicks: Sale
  resetAllSaleClicks: Array<Sale>
  incrementSaleClick: Scalars['Boolean']
  toggleHidden: Scalars['Boolean']
  removeSale: Scalars['Boolean']
  createTag: Tag
  removeUpload: Scalars['Boolean']
  deleteAccount: Scalars['Boolean']
  updateUsername: User
  updateEmail: User
  toggleUserCommentBan: User
  toggleChannelSubscription: User
  setUserPushSubscription: Scalars['Boolean']
  toggleDisableNewUploadEmail: Scalars['Boolean']
  setPushRequested: Scalars['Boolean']
  createUserPlaylist: UserPlaylist
  updateUserPlaylist: UserPlaylist
  removeUserPlaylist: Scalars['Boolean']
  addVideoToUserPlaylist: UserPlaylist
  removeVideoFromUserPlaylist: UserPlaylist
  addWatchLaterToUsers: Scalars['Boolean']
  setVideoTimeWatched: Scalars['Boolean']
  incrementViewCount: Scalars['Boolean']
  incrementLikeCount: Video
  incrementAngryCount: Video
  toggleCommentsActive: Scalars['Boolean']
  createVideo: Video
  updateVideo: Video
  removeVideo: Scalars['Boolean']
  likeComment: Comment
  dislikeComment: Comment
  likeVideo: Video
  dislikeVideo: Video
  videoPageLimit: Scalars['Float']
  userPageLimit: Scalars['Float']
  commentPageLimit: Scalars['Float']
}

export type MutationCreateUserArgs = {
  user: CreateUserInput
}

export type MutationVerifyEmailArgs = {
  token: Scalars['String']
}

export type MutationResetPasswordArgs = {
  token: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationSendVerificationEmailArgs = {
  email: Scalars['String']
}

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']
}

export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationTwoFactorSetArgs = {
  secret: TwoFactorSecretKeyInput
  code: Scalars['String']
}

export type MutationTwoFactorUnsetArgs = {
  code: Scalars['String']
}

export type MutationImpersonateArgs = {
  accessToken: Scalars['String']
  username: Scalars['String']
}

export type MutationRefreshTokensArgs = {
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type MutationAuthenticateArgs = {
  serviceName: Scalars['String']
  params: AuthenticateParamsInput
}

export type MutationLoginAdminArgs = {
  password: Scalars['String']
  email: Scalars['String']
}

export type MutationSignUpAdminUserArgs = {
  password: Scalars['String']
  email: Scalars['String']
}

export type MutationResetAdminUserPasswordArgs = {
  newPassword: Scalars['String']
  email: Scalars['String']
}

export type MutationResetPasswordAdminArgs = {
  newPasswordConfirmed: Scalars['String']
  newPassword: Scalars['String']
  currentPassword: Scalars['String']
}

export type MutationResetPasswordAdminTokenArgs = {
  newPasswordConfirmed: Scalars['String']
  newPassword: Scalars['String']
  token: Scalars['String']
}

export type MutationForgotPasswordAdminArgs = {
  email: Scalars['String']
}

export type MutationSetAdminUserPermissionsArgs = {
  channels: Array<Scalars['String']>
  roles: Array<Roles>
  id: Scalars['String']
}

export type MutationSuspendAdminUserArgs = {
  id: Scalars['String']
}

export type MutationCreateChannelArgs = {
  channel: ChannelInput
}

export type MutationUpdateChannelPlaylistsOrderArgs = {
  order: Array<Scalars['String']>
  id: Scalars['String']
}

export type MutationUpdateChannelArgs = {
  channel: UpdateChannelInput
  id: Scalars['String']
}

export type MutationRemoveChannelFeaturedVideoArgs = {
  id: Scalars['String']
}

export type MutationRemoveChannelLiveStreamVideoArgs = {
  id: Scalars['String']
}

export type MutationRemoveChannelArgs = {
  id: Scalars['String']
}

export type MutationCreateCommentArgs = {
  comment: CommentInput
}

export type MutationApproveCommentArgs = {
  id: Scalars['String']
}

export type MutationRejectCommentArgs = {
  id: Scalars['String']
}

export type MutationReportCommentArgs = {
  report: ReportInput
}

export type MutationRemoveCommentArgs = {
  id: Scalars['String']
}

export type MutationCreateConfigArgs = {
  config: ConfigInput
}

export type MutationUpdateConfigArgs = {
  config: UpdateConfigInput
  id: Scalars['String']
}

export type MutationSubmitContributorSignupArgs = {
  signup: SubmitContributorSignupInput
}

export type MutationCreateHomeLayoutArgs = {
  homeLayout: HomeLayoutInput
}

export type MutationUpdateHomeLayoutArgs = {
  homeLayout: UpdateHomeLayoutInput
  id: Scalars['String']
}

export type MutationSetNotificationOpenedArgs = {
  id: Scalars['String']
}

export type MutationSetNotificationsSeenArgs = {
  ids: Array<Scalars['String']>
}

export type MutationSetPushNotificationReceivedArgs = {
  token: Scalars['String']
  id: Scalars['String']
}

export type MutationSetPushNotificationOpenedArgs = {
  token: Scalars['String']
  id: Scalars['String']
}

export type MutationCreatePlaylistArgs = {
  playlist: PlaylistInput
}

export type MutationUpdatePlaylistArgs = {
  playlist: UpdatePlaylistInput
  id: Scalars['String']
}

export type MutationRemovePlaylistArgs = {
  id: Scalars['String']
}

export type MutationAddVideosToPlaylistArgs = {
  videoIds: Array<Scalars['String']>
  id: Scalars['String']
}

export type MutationRemoveVideosFromPlaylistArgs = {
  videoIds: Array<Scalars['String']>
  id: Scalars['String']
}

export type MutationCreateSaleArgs = {
  textSale: Scalars['String']
  videoUploadId: Scalars['String']
  url: Scalars['String']
  description: Scalars['String']
  text: Scalars['String']
}

export type MutationUpdateSaleArgs = {
  textSale: Scalars['String']
  url: Scalars['String']
  description: Scalars['String']
  text: Scalars['String']
  id: Scalars['String']
}

export type MutationResetSaleClicksArgs = {
  id: Scalars['String']
}

export type MutationIncrementSaleClickArgs = {
  id: Scalars['String']
}

export type MutationToggleHiddenArgs = {
  isChecked: Scalars['Boolean']
  id: Scalars['String']
}

export type MutationRemoveSaleArgs = {
  id: Scalars['String']
}

export type MutationCreateTagArgs = {
  name: Scalars['String']
}

export type MutationRemoveUploadArgs = {
  id: Scalars['String']
}

export type MutationUpdateUsernameArgs = {
  username: Scalars['String']
}

export type MutationUpdateEmailArgs = {
  email: Scalars['String']
}

export type MutationToggleUserCommentBanArgs = {
  id: Scalars['String']
}

export type MutationToggleChannelSubscriptionArgs = {
  id: Scalars['String']
}

export type MutationSetUserPushSubscriptionArgs = {
  pushSubscription: PushSubscriptionInput
}

export type MutationCreateUserPlaylistArgs = {
  userPlaylist: UserPlaylistInput
}

export type MutationUpdateUserPlaylistArgs = {
  userPlaylist: UpdateUserPlaylistInput
  id: Scalars['String']
}

export type MutationRemoveUserPlaylistArgs = {
  id: Scalars['String']
}

export type MutationAddVideoToUserPlaylistArgs = {
  videoId: Scalars['String']
  id: Scalars['String']
}

export type MutationRemoveVideoFromUserPlaylistArgs = {
  videoId: Scalars['String']
  id: Scalars['String']
}

export type MutationSetVideoTimeWatchedArgs = {
  timeWatched: Scalars['Float']
  id: Scalars['String']
}

export type MutationIncrementViewCountArgs = {
  id: Scalars['String']
}

export type MutationIncrementLikeCountArgs = {
  id: Scalars['String']
}

export type MutationIncrementAngryCountArgs = {
  id: Scalars['String']
}

export type MutationToggleCommentsActiveArgs = {
  id: Scalars['String']
}

export type MutationCreateVideoArgs = {
  video: VideoInput
}

export type MutationUpdateVideoArgs = {
  create?: Maybe<Scalars['Boolean']>
  video: UpdateVideoInput
  id: Scalars['String']
}

export type MutationRemoveVideoArgs = {
  id: Scalars['String']
}

export type MutationLikeCommentArgs = {
  id: Scalars['String']
}

export type MutationDislikeCommentArgs = {
  id: Scalars['String']
}

export type MutationLikeVideoArgs = {
  id: Scalars['String']
}

export type MutationDislikeVideoArgs = {
  id: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  _id: Scalars['ID']
  user: User
  seen: Scalars['Boolean']
  opened: Scalars['Boolean']
  hidden: Scalars['Boolean']
  receivedPush: Scalars['Boolean']
  openedPush: Scalars['Boolean']
  openedEmail: Scalars['Boolean']
  type: NotificationType
  comment?: Maybe<Comment>
  video?: Maybe<Video>
  channel?: Maybe<Channel>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

/** Type of notification */
export enum NotificationType {
  CommentReply = 'commentReply',
  CommentMention = 'commentMention',
  NewVideo = 'newVideo',
}

export type Playlist = {
  __typename?: 'Playlist'
  _id: Scalars['ID']
  title?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  channel?: Maybe<Channel>
  creator: AdminUser
  videos: Array<Video>
  isPublic?: Maybe<Scalars['Boolean']>
  isGlobal?: Maybe<Scalars['Boolean']>
  totalVideos: Scalars['Float']
  totalVideoViews: Scalars['Float']
  videoIds: Array<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type PlaylistVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type PlaylistInput = {
  title: Scalars['String']
  channel?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  isPublic?: Maybe<Scalars['Boolean']>
  isGlobal?: Maybe<Scalars['Boolean']>
  videoIds: Array<Scalars['String']>
}

export type Poster = {
  __typename?: 'Poster'
  id: Scalars['String']
  largeUrl: Scalars['String']
  thumbnailUrl: Scalars['String']
}

export type PushSubscription = {
  __typename?: 'PushSubscription'
  endpoint: Scalars['String']
  p256dh: Scalars['String']
  auth: Scalars['String']
}

export type PushSubscriptionInput = {
  endpoint: Scalars['String']
  p256dh: Scalars['String']
  auth: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  twoFactorSecret?: Maybe<TwoFactorSecretKey>
  getUser?: Maybe<User>
  getAdminActions: Array<AdminAction>
  meAdmin: AdminUser
  getAdminUser: AdminUser
  getAdminUsers: Array<AdminUser>
  getAdminUserByEmail: AdminUser
  getRecentAdminUsers: Array<AdminUser>
  verifyToken: Scalars['Boolean']
  getChannel: Channel
  getChannelByIdOrTitle: Channel
  getAdminChannel: Channel
  getChannels: Array<Channel>
  getAllChannels: Array<Channel>
  getAdminChannels: Array<Channel>
  getRecentChannels: Array<Channel>
  getComment: Comment
  getComments: Array<Comment>
  getAllComments: Array<Comment>
  getAllCommentsAmountSince: Scalars['Float']
  getReportedComments: Array<Comment>
  getReportedCommentsAmountSince: Scalars['Float']
  getApprovedComments: Array<Comment>
  getApprovedCommentsAmountSince: Scalars['Float']
  getRejectedComments: Array<Comment>
  getRejectedCommentsAmountSince: Scalars['Float']
  getUnfilteredComments: Array<Comment>
  getUnfilteredCommentsAmountSince: Scalars['Float']
  getVideoComments: Array<Comment>
  getAllVideoComments: Array<Comment>
  getUserComments: Array<Comment>
  getCommentReplies: Array<Comment>
  getCommentReports: Array<Report>
  getConfig: Config
  getActiveConfig: Config
  adminGetActiveConfig: Config
  getHomeLayout: HomeLayout
  getActiveHomeLayout: HomeLayout
  adminGetActiveHomeLayout: HomeLayout
  getMyNotifications: Array<Notification>
  getPlaylist: Playlist
  getAdminPlaylist: Playlist
  getPlaylists: Array<Playlist>
  getAllPlaylists: Array<Playlist>
  getAdminPlaylists: Array<Playlist>
  getPoster: Array<Poster>
  getSale: Sale
  getSales: Array<Sale>
  search: SearchResult
  getTag: Tag
  getTags: Array<Tag>
  getTagByName: Tag
  getTagVideos: Array<Video>
  getUpload: Upload
  me?: Maybe<User>
  user?: Maybe<User>
  getAllUsers: Array<User>
  getMyAngryHistory: Array<UserAngryHistory>
  getMyLikeHistory: Array<UserLikeHistory>
  getUserPlaylist: UserPlaylist
  getUserPlaylists: Array<UserPlaylist>
  getMyWatchHistory: Array<UserWatchHistory>
  getVideo: Video
  getVideos: Array<Video>
  getScheduledVideos: Array<Video>
  getHotVideos: Array<Video>
  getNewVideos: Array<Video>
  getSubscribedVideos: Array<Video>
  getLiveVideoStatus: Scalars['Boolean']
  getVideoViews: Array<VideoView>
  getVideoVideoViews: Array<VideoView>
  getChannelVideoViews: Array<VideoView>
  getPlaylistVideoViews: Array<VideoView>
  getUserVideoViews: Array<VideoView>
  getVotes: Array<Vote>
  getVideoVoteCount: VoteCount
  getCommentVoteCount: VoteCount
  getUserVotes: Array<Vote>
  videoPageLimit: Scalars['Float']
  userPageLimit: Scalars['Float']
  commentPageLimit: Scalars['Float']
}

export type QueryGetAdminActionsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetAdminUserArgs = {
  id: Scalars['String']
}

export type QueryGetAdminUserByEmailArgs = {
  email: Scalars['String']
}

export type QueryGetRecentAdminUsersArgs = {
  offset: Scalars['Float']
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetChannelArgs = {
  id: Scalars['String']
}

export type QueryGetChannelByIdOrTitleArgs = {
  id: Scalars['String']
}

export type QueryGetAdminChannelArgs = {
  id: Scalars['String']
}

export type QueryGetChannelsArgs = {
  ids: Array<Scalars['String']>
}

export type QueryGetAllChannelsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetAdminChannelsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetRecentChannelsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetCommentArgs = {
  id: Scalars['String']
}

export type QueryGetCommentsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetAllCommentsArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetAllCommentsAmountSinceArgs = {
  since: Scalars['DateTime']
}

export type QueryGetReportedCommentsArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetReportedCommentsAmountSinceArgs = {
  since: Scalars['DateTime']
}

export type QueryGetApprovedCommentsArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetApprovedCommentsAmountSinceArgs = {
  since: Scalars['DateTime']
}

export type QueryGetRejectedCommentsArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetRejectedCommentsAmountSinceArgs = {
  since: Scalars['DateTime']
}

export type QueryGetUnfilteredCommentsArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetUnfilteredCommentsAmountSinceArgs = {
  since: Scalars['DateTime']
}

export type QueryGetVideoCommentsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetAllVideoCommentsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetUserCommentsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetCommentRepliesArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetCommentReportsArgs = {
  id: Scalars['String']
}

export type QueryGetConfigArgs = {
  id: Scalars['String']
}

export type QueryGetHomeLayoutArgs = {
  id: Scalars['String']
}

export type QueryGetMyNotificationsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetPlaylistArgs = {
  id: Scalars['String']
}

export type QueryGetAdminPlaylistArgs = {
  id: Scalars['String']
}

export type QueryGetPlaylistsArgs = {
  ids: Array<Scalars['String']>
}

export type QueryGetAllPlaylistsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetAdminPlaylistsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetPosterArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetSaleArgs = {
  id: Scalars['String']
}

export type QuerySearchArgs = {
  category?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  query: Scalars['String']
}

export type QueryGetTagArgs = {
  id: Scalars['String']
}

export type QueryGetTagsArgs = {
  searchQuery: Scalars['String']
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetTagByNameArgs = {
  name: Scalars['String']
}

export type QueryGetTagVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetUploadArgs = {
  id: Scalars['String']
}

export type QueryUserArgs = {
  username?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type QueryGetAllUsersArgs = {
  query?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetMyAngryHistoryArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetMyLikeHistoryArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetUserPlaylistArgs = {
  id: Scalars['String']
}

export type QueryGetUserPlaylistsArgs = {
  ids: Array<Scalars['String']>
}

export type QueryGetMyWatchHistoryArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetVideoArgs = {
  id: Scalars['String']
}

export type QueryGetVideosArgs = {
  ids: Array<Scalars['String']>
}

export type QueryGetHotVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetNewVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetSubscribedVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
}

export type QueryGetLiveVideoStatusArgs = {
  id: Scalars['String']
}

export type QueryGetVideoViewsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetVideoVideoViewsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetChannelVideoViewsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetPlaylistVideoViewsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetUserVideoViewsArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type QueryGetVotesArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type QueryGetVideoVoteCountArgs = {
  id: Scalars['String']
}

export type QueryGetCommentVoteCountArgs = {
  id: Scalars['String']
}

export type QueryGetUserVotesArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
  id: Scalars['String']
}

export type Report = {
  __typename?: 'Report'
  id: Scalars['String']
  user: User
  content: Scalars['String']
  type: ReportType
}

export type ReportInput = {
  content: Scalars['String']
  commentId: Scalars['String']
}

export enum ReportType {
  Report = 'report',
  Spam = 'spam',
}

export type ReqHeaders = {
  __typename?: 'ReqHeaders'
  ipAddress: Scalars['String']
  userAgent: Scalars['String']
  referrer: Scalars['String']
}

/** Roles for admins and users */
export enum Roles {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
  Uploader = 'uploader',
  Moderator = 'moderator',
  Sales = 'sales',
  User = 'user',
  Anon = 'anon',
}

export type Sale = {
  __typename?: 'Sale'
  _id: Scalars['ID']
  type: SaleType
  text: Scalars['String']
  textSale?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  isHidden?: Maybe<Scalars['Boolean']>
  videoUpload?: Maybe<Upload>
  clicks: Scalars['Float']
  amountServed: Scalars['Float']
  creator: AdminUser
  clickRate?: Maybe<Scalars['Float']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

/** Type of sale */
export enum SaleType {
  Text = 'text',
  Video = 'video',
}

export type SearchResult = {
  __typename?: 'SearchResult'
  videos?: Maybe<Array<Video>>
  channels?: Maybe<Array<Channel>>
}

export type ShowInfo = {
  __typename?: 'ShowInfo'
  times: Scalars['String']
  phone: Scalars['String']
}

export type ShowInfoInput = {
  times: Scalars['String']
  phone: Scalars['String']
}

export type SubmitContributorSignupInput = {
  fullName: Scalars['String']
  email: Scalars['String']
  twitter?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  youtube?: Maybe<Scalars['String']>
  otherSocialProfiles?: Maybe<Scalars['String']>
  video1: Scalars['String']
  video2: Scalars['String']
}

export type SubscribedChannel = {
  __typename?: 'SubscribedChannel'
  channel: Channel
  lastSeen: Scalars['DateTime']
}

export type Tag = {
  __typename?: 'Tag'
  _id: Scalars['ID']
  name: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type Tokens = {
  __typename?: 'Tokens'
  refreshToken?: Maybe<Scalars['String']>
  accessToken?: Maybe<Scalars['String']>
}

export type TwoFactorSecretKey = {
  __typename?: 'TwoFactorSecretKey'
  ascii?: Maybe<Scalars['String']>
  base32?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  qr_code_ascii?: Maybe<Scalars['String']>
  qr_code_hex?: Maybe<Scalars['String']>
  qr_code_base32?: Maybe<Scalars['String']>
  google_auth_qr?: Maybe<Scalars['String']>
  otpauth_url?: Maybe<Scalars['String']>
}

export type TwoFactorSecretKeyInput = {
  ascii?: Maybe<Scalars['String']>
  base32?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  qr_code_ascii?: Maybe<Scalars['String']>
  qr_code_hex?: Maybe<Scalars['String']>
  qr_code_base32?: Maybe<Scalars['String']>
  google_auth_qr?: Maybe<Scalars['String']>
  otpauth_url?: Maybe<Scalars['String']>
}

export type UpdateChannelInput = {
  title?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  textInfo?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  coverImage?: Maybe<Scalars['String']>
  featuredVideo?: Maybe<Scalars['String']>
  liveStreamVideo?: Maybe<Scalars['String']>
  showInfo?: Maybe<ShowInfoInput>
  links?: Maybe<ChannelLinksInput>
  alwaysLive?: Maybe<Scalars['Boolean']>
  dayTimesLive?: Maybe<DayTimesLiveInput>
}

export type UpdateConfigInput = {
  isActive: Scalars['Boolean']
  featuredVideoIds?: Maybe<Array<Scalars['String']>>
  navigationChannelIds?: Maybe<Array<Scalars['String']>>
  headerSaleActive?: Maybe<Scalars['Boolean']>
}

export type UpdateHomeLayoutInput = {
  isActive: Scalars['Boolean']
  featuredVideoIds?: Maybe<Array<Scalars['String']>>
  navigationChannelIds?: Maybe<Array<Scalars['String']>>
}

export type UpdatePlaylistInput = {
  title?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  isPublic?: Maybe<Scalars['Boolean']>
  videoIds?: Maybe<Array<Scalars['String']>>
}

export type UpdateUserPlaylistInput = {
  title?: Maybe<Scalars['String']>
  isPublic?: Maybe<Scalars['Boolean']>
}

export type UpdateVideoInput = {
  title?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  channelId?: Maybe<Scalars['String']>
  published?: Maybe<Scalars['Boolean']>
  disableComment?: Maybe<Scalars['Boolean']>
  unlisted?: Maybe<Scalars['Boolean']>
  live?: Maybe<Scalars['Boolean']>
  posterUploadId?: Maybe<Scalars['String']>
  videoUploadId?: Maybe<Scalars['String']>
  audioUploadId?: Maybe<Scalars['String']>
  liveStreamUrl?: Maybe<Scalars['String']>
  tagIds?: Maybe<Array<Scalars['String']>>
  newTags?: Maybe<Array<Scalars['String']>>
  useFeed?: Maybe<Scalars['Boolean']>
  feed?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
}

export type Upload = {
  __typename?: 'Upload'
  _id: Scalars['ID']
  name: Scalars['String']
  key?: Maybe<Scalars['String']>
  bucket?: Maybe<Scalars['String']>
  thumbnailImageKey?: Maybe<Scalars['String']>
  largeImageKey?: Maybe<Scalars['String']>
  mimetype: Scalars['String']
  size: Scalars['Float']
  creator: AdminUser
  cloudflareVideoUID?: Maybe<Scalars['String']>
  cloudflareVideoReady: Scalars['Boolean']
  videoDuration?: Maybe<Scalars['Float']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  emails?: Maybe<Array<EmailRecord>>
  username?: Maybe<Scalars['String']>
  _id: Scalars['ID']
  karma: Scalars['Float']
  commentBan?: Maybe<Scalars['Boolean']>
  subscribedChannels: Array<SubscribedChannel>
  deactivated?: Maybe<Scalars['Boolean']>
  pushSubscriptions?: Maybe<Array<PushSubscription>>
  emailNotificationSettings?: Maybe<EmailNotificationSettings>
  pushRequested: Scalars['Boolean']
  chatToken: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  watchLaterPlaylist: UserPlaylist
}

export type UserAngryHistory = {
  __typename?: 'UserAngryHistory'
  _id: Scalars['ID']
  video: Video
  user: User
  dateDisLiked: Scalars['DateTime']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type UserInput = {
  id?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
}

export type UserLikeHistory = {
  __typename?: 'UserLikeHistory'
  _id: Scalars['ID']
  video: Video
  user: User
  dateLiked: Scalars['DateTime']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type UserPlaylist = {
  __typename?: 'UserPlaylist'
  _id: Scalars['ID']
  title: Scalars['String']
  user: User
  videos: Array<Video>
  isPublic: Scalars['Boolean']
  isWatchLater: Scalars['Boolean']
  totalVideos: Scalars['Float']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type UserPlaylistVideosArgs = {
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Float']>
}

export type UserPlaylistInput = {
  title: Scalars['String']
  isPublic?: Maybe<Scalars['Boolean']>
  videoIds: Array<Scalars['String']>
}

export type UserWatchHistory = {
  __typename?: 'UserWatchHistory'
  _id: Scalars['ID']
  video: Video
  user: User
  dateLastWatched: Scalars['DateTime']
  timeWatched?: Maybe<Scalars['Float']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type Video = {
  __typename?: 'Video'
  _id: Scalars['ID']
  title: Scalars['String']
  summary: Scalars['String']
  published: Scalars['Boolean']
  unlisted?: Maybe<Scalars['Boolean']>
  creator: AdminUser
  channel: Channel
  tags: Array<Tag>
  videoDuration?: Maybe<Scalars['Float']>
  audioUpload?: Maybe<Upload>
  posterUpload?: Maybe<Upload>
  videoUpload?: Maybe<Upload>
  live: Scalars['Boolean']
  disableComment?: Maybe<Scalars['Boolean']>
  liveStreamUrl?: Maybe<Scalars['String']>
  timeWatched?: Maybe<Scalars['Float']>
  isInWatchLater?: Maybe<Scalars['Boolean']>
  largeImage?: Maybe<Scalars['String']>
  playCount: Scalars['Float']
  likeCount: Scalars['Float']
  angerCount: Scalars['Float']
  liked?: Maybe<Scalars['Boolean']>
  disLiked?: Maybe<Scalars['Boolean']>
  sale?: Maybe<Sale>
  publishedAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  thumbnailImage?: Maybe<Scalars['String']>
  streamUrl?: Maybe<Scalars['String']>
  directUrl?: Maybe<Scalars['String']>
  liveInfo?: Maybe<LiveInfo>
  embedUrl: Scalars['String']
  disliked: Scalars['Boolean']
  audio?: Maybe<Scalars['String']>
  playlists: Array<Playlist>
}

export type VideoInfo = {
  __typename?: 'VideoInfo'
  ingestURL: Scalars['String']
  ingestUser: Scalars['String']
  ingestPassword: Scalars['String']
  streamKey: Scalars['String']
}

export type VideoInput = {
  title: Scalars['String']
  summary: Scalars['String']
  channelId: Scalars['String']
  playlistId: Scalars['String']
  published: Scalars['Boolean']
  disableComment?: Maybe<Scalars['Boolean']>
  unlisted: Scalars['Boolean']
  live?: Maybe<Scalars['Boolean']>
  posterUploadId: Scalars['String']
  videoUploadId?: Maybe<Scalars['String']>
  audioUploadId?: Maybe<Scalars['String']>
  liveStreamUrl?: Maybe<Scalars['String']>
  tagIds?: Maybe<Array<Scalars['String']>>
  newTags?: Maybe<Array<Scalars['String']>>
  publishedAt: Scalars['DateTime']
  useFeed?: Maybe<Scalars['Boolean']>
  feed?: Maybe<Scalars['String']>
}

export type VideoView = {
  __typename?: 'VideoView'
  _id: Scalars['ID']
  video: Video
  channel: Channel
  playlist: Playlist
  user: User
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type Vote = {
  __typename?: 'Vote'
  _id: Scalars['ID']
  video?: Maybe<Video>
  comment?: Maybe<Comment>
  user: User
  type: VoteType
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type VoteCount = {
  __typename?: 'VoteCount'
  positive: Scalars['Float']
  negative: Scalars['Float']
}

/** Type of vote */
export enum VoteType {
  Positive = 'positive',
  Negative = 'negative',
}
export type AdminUserListDataFragment = ({ __typename?: 'AdminUser' } & Pick<
  AdminUser,
  '_id' | 'email'
>) &
  AdminUserPermissionModalDataFragment

export type GetAdminUserPermissionModalChannelsQueryVariables = {}

export type GetAdminUserPermissionModalChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>>
}

export type AdminUserPermissionModalDataFragment = { __typename?: 'AdminUser' } & Pick<
  AdminUser,
  '_id' | 'email' | 'roles'
> & {
    collaboratingChannels: Maybe<Array<Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id'>>>>
  }

export type ResetPasswordAdminMutationVariables = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
  newPasswordConfirmed: Scalars['String']
}

export type ResetPasswordAdminMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPasswordAdmin'
>

export type ChannelCardDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title' | 'totalVideoViews' | 'createdAt'
>

export type ChannelEditDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title' | 'summary' | 'avatar' | 'coverImage' | 'alwaysLive'
> & {
    dayTimesLive: Maybe<
      { __typename?: 'DayTimesLive' } & Pick<
        DayTimesLive,
        'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'
      >
    >
    showInfo: Maybe<{ __typename?: 'ShowInfo' } & Pick<ShowInfo, 'times' | 'phone'>>
    featuredVideo: Maybe<{ __typename?: 'Video' } & Pick<Video, '_id'>>
    liveStreamVideo: Maybe<{ __typename?: 'Video' } & Pick<Video, '_id'>>
    links: Maybe<
      { __typename?: 'ChannelLinks' } & Pick<
        ChannelLinks,
        'website' | 'facebook' | 'twitter' | 'gab' | 'minds' | 'subscribeStar'
      >
    >
  }

export type ChannelFeaturedVideoDataFragment = { __typename?: 'Channel' } & Pick<Channel, '_id'> & {
    featuredVideo: Maybe<{ __typename?: 'Video' } & VideoCardDataFragment & VideoTableDataFragment>
  }

export type ChannelInfoDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title' | 'summary'
> & {
    links: Maybe<
      { __typename?: 'ChannelLinks' } & Pick<
        ChannelLinks,
        'website' | 'facebook' | 'telegram' | 'twitter' | 'gab' | 'minds'
      >
    >
  }

export type ChannelListItemDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title' | 'summary'
> & { videos: Array<{ __typename?: 'Video' } & VideoCardDataFragment> }

export type ChannelLiveStreamVideoDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id'
> & {
    liveStreamVideo: Maybe<
      { __typename?: 'Video' } & VideoCardDataFragment & VideoTableDataFragment
    >
  }

export type GetChannelPickerQueryVariables = {}

export type GetChannelPickerQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<
    { __typename?: 'Channel' } & ChannelTableDataFragment & ChannelCardDataFragment
  >
}

export type ChannelPlaylistsDataFragment = { __typename?: 'Channel' } & Pick<Channel, '_id'> & {
    playlists: Maybe<
      Array<{ __typename?: 'Playlist' } & PlaylistListItemDataFragment & PlaylistTableDataFragment>
    >
  }

export type ChannelTableDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title' | 'totalVideoViews' | 'totalVideos' | 'totalPlaylists' | 'createdAt'
>

export type ChannelVideosDataFragment = { __typename?: 'Channel' } & Pick<Channel, '_id'> & {
    videos: Array<{ __typename?: 'Video' } & VideoCardDataFragment & VideoTableDataFragment>
  }

export type ChannelViewDataFragment = ({ __typename?: 'Channel' } & Pick<Channel, '_id'>) &
  ChannelInfoDataFragment &
  ChannelLiveStreamVideoDataFragment &
  ChannelFeaturedVideoDataFragment &
  ChannelPlaylistsDataFragment &
  ChannelVideosDataFragment

export type CommentContentDataFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  '_id' | 'content' | 'createdAt'
> & {
    replyTo: Maybe<{ __typename?: 'Comment' } & Pick<Comment, '_id'>>
    reports: Array<{ __typename?: 'Report' } & Pick<Report, 'content'>>
    video: Maybe<
      { __typename?: 'Video' } & Pick<Video, '_id' | 'title'> & {
          channel: { __typename?: 'Channel' } & Pick<Channel, '_id'>
        }
    >
    user: { __typename?: 'User' } & Pick<User, 'username'>
  }

export type CommentListDataFragment = ({ __typename?: 'Comment' } & Pick<Comment, '_id'>) &
  CommentContentDataFragment &
  CommentModerationCardDataFragment

export type CommentModerationCardDataFragment = ({ __typename?: 'Comment' } & Pick<
  Comment,
  | '_id'
  | 'spam'
  | 'reported'
  | 'approved'
  | 'rejected'
  | 'bannedFromComment'
  | 'rejectedByUploader'
  | 'duplicate'
>) &
  CommentContentDataFragment

export type GetCommentReportsQueryVariables = {
  id: Scalars['String']
}

export type GetCommentReportsQuery = { __typename?: 'Query' } & {
  getCommentReports: Array<{ __typename?: 'Report' } & Pick<Report, 'id' | 'type' | 'content'>>
}

export type CommentTabDataFragment = ({ __typename?: 'Comment' } & Pick<
  Comment,
  '_id' | 'createdAt'
>) &
  CommentModerationCardDataFragment

export type DraggableChannelListFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title'
>

export type DraggbleVideoListDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'largeImage'
>

export type GetHomeEditQueryVariables = {
  videoIds: Array<Scalars['String']>
}

export type GetHomeEditQuery = { __typename?: 'Query' } & {
  getVideos: Array<{ __typename?: 'Video' } & DraggbleVideoListDataFragment>
  getAdminChannels: Array<{ __typename?: 'Channel' } & DraggableChannelListFragment>
}

export type GetLiveVideoUploadChannelsQueryVariables = {}

export type GetLiveVideoUploadChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<
    { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'> & {
        playlists: Maybe<Array<{ __typename?: 'Playlist' } & Pick<Playlist, '_id' | 'title'>>>
      }
  >
}

export type ChannelFieldsFragment = { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>

export type ChannelFieldsExtendedFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title'
>

export type GetChannelFieldsQueryVariables = {
  id: Scalars['String']
}

export type GetChannelFieldsQuery = { __typename?: 'Query' } & {
  getAdminChannel: { __typename?: 'Channel' } & ChannelFieldsFragment
}

export type PlaylistFieldsFragment = { __typename?: 'Playlist' } & Pick<
  Playlist,
  '_id' | 'title'
> & { channel: Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>> }

export type GetPlaylistFieldsQueryVariables = {
  id: Scalars['String']
}

export type GetPlaylistFieldsQuery = { __typename?: 'Query' } & {
  getAdminPlaylist: { __typename?: 'Playlist' } & PlaylistFieldsFragment
}

export type PageItemsListDataFragment = { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>

export type GetPlaylistAddChannelsQueryVariables = {}

export type GetPlaylistAddChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>>
}

export type PlaylistCardDataFragment = { __typename?: 'Playlist' } & Pick<
  Playlist,
  '_id' | 'title' | 'isGlobal' | 'totalVideoViews' | 'createdAt'
> & { channel: Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>> }

export type PlaylistListItemDataFragment = { __typename?: 'Playlist' } & Pick<
  Playlist,
  '_id' | 'title' | 'summary' | 'isGlobal'
> & {
    channel: Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>>
    videos: Array<{ __typename?: 'Video' } & VideoTableDataFragment & VideoCardDataFragment>
  }

export type GetPlaylistPickerDataQueryVariables = {}

export type GetPlaylistPickerDataQuery = { __typename?: 'Query' } & {
  getAdminPlaylists: Array<
    ({ __typename?: 'Playlist' } & Pick<Playlist, '_id'>) &
      PlaylistCardDataFragment &
      PlaylistTableDataFragment
  >
}

export type PlaylistTableDataFragment = { __typename?: 'Playlist' } & Pick<
  Playlist,
  '_id' | 'title' | 'totalVideos' | 'totalVideoViews' | 'isPublic' | 'createdAt' | 'isGlobal'
> & { channel: Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>> }

export type GetHeaderSaleActiveQueryVariables = {}

export type GetHeaderSaleActiveQuery = { __typename?: 'Query' } & {
  adminGetActiveConfig: { __typename?: 'Config' } & Pick<Config, '_id' | 'headerSaleActive'>
}

export type UpdateHeaderSaleMutationVariables = {
  id: Scalars['String']
  config: UpdateConfigInput
}

export type UpdateHeaderSaleMutation = { __typename?: 'Mutation' } & {
  updateConfig: { __typename?: 'Config' } & Pick<Config, '_id' | 'headerSaleActive'>
}

export type SalesTextListDataFragment = { __typename?: 'Sale' } & Pick<
  Sale,
  | '_id'
  | 'text'
  | 'textSale'
  | 'description'
  | 'url'
  | 'isHidden'
  | 'clickRate'
  | 'clicks'
  | 'amountServed'
  | 'createdAt'
> & { videoUpload: Maybe<{ __typename?: 'Upload' } & Pick<Upload, 'cloudflareVideoUID'>> }

export type ScheduledVideoTableDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'publishedAt' | 'createdAt'
> & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'> }

export type SearchQueryVariables = {
  query: Scalars['String']
  category: Scalars['String']
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
}

export type SearchQuery = { __typename?: 'Query' } & {
  search: { __typename?: 'SearchResult' } & {
    videos: Maybe<Array<{ __typename?: 'Video' } & VideoCardDataFragment & VideoTableDataFragment>>
  }
}

export type GetTagsQueryVariables = {
  searchQuery: Scalars['String']
}

export type GetTagsQuery = { __typename?: 'Query' } & {
  getTags: Array<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name'>>
}

export type GetAllUsersQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetAllUsersQuery = { __typename?: 'Query' } & Pick<Query, 'userPageLimit'> & {
    getAllUsers: Array<{ __typename?: 'User' } & Pick<User, '_id' | 'username'>>
  }

export type MeAdminQueryVariables = {}

export type MeAdminQuery = { __typename?: 'Query' } & {
  meAdmin: { __typename?: 'AdminUser' } & Pick<
    AdminUser,
    '_id' | 'email' | 'isAdmin' | 'displayName' | 'roles'
  > & {
      collaboratingChannels: Maybe<Array<Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id'>>>>
    }
}

export type GetPlaylistsForVideoPageQueryVariables = {
  limit?: Maybe<Scalars['Float']>
}

export type GetPlaylistsForVideoPageQuery = { __typename?: 'Query' } & {
  getAdminPlaylists: Array<
    { __typename?: 'Playlist' } & Pick<Playlist, '_id' | 'title' | 'isGlobal'> & {
        channel: Maybe<{ __typename?: 'Channel' } & Pick<Channel, '_id'>>
      }
  >
}

export type VideoCardDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'largeImage' | 'published' | 'unlisted' | 'live' | 'playCount' | 'createdAt'
> & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id'> }

export type GetVideoCommentsQueryVariables = {
  id: Scalars['String']
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
}

export type GetVideoCommentsQuery = { __typename?: 'Query' } & Pick<Query, 'commentPageLimit'> & {
    getVideoComments: Array<{ __typename?: 'Comment' } & CommentListDataFragment>
  }

export type GetAllVideoCommentsQueryVariables = {
  id: Scalars['String']
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
}

export type GetAllVideoCommentsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'commentPageLimit'
> & { getAllVideoComments: Array<{ __typename?: 'Comment' } & CommentModerationCardDataFragment> }

export type GetVideoPickerChannelsQueryVariables = {}

export type GetVideoPickerChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<{ __typename?: 'Channel' } & VideoPickerFiltersPropsDataFragment>
}

export type VideoPickerFiltersPropsDataFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  '_id' | 'title'
>

export type GetVideoPickerVideosQueryVariables = {
  id: Scalars['String']
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
}

export type GetVideoPickerVideosQuery = { __typename?: 'Query' } & Pick<Query, 'videoPageLimit'> & {
    getAdminChannel: { __typename?: 'Channel' } & Pick<Channel, '_id' | 'totalVideos'> & {
        videos: Array<
          ({ __typename?: 'Video' } & Pick<Video, '_id' | 'live'>) &
            VideoCardDataFragment &
            VideoTableDataFragment
        >
      }
  }

export type VideoPlayerDataFragment = { __typename?: 'Video' } & Pick<Video, '_id' | 'streamUrl'>

export type VideoTableDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'playCount' | 'published' | 'createdAt'
> & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id'> }

export type GetVideoUploadChannelsQueryVariables = {}

export type GetVideoUploadChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<
    { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'> & {
        playlists: Maybe<Array<{ __typename?: 'Playlist' } & Pick<Playlist, '_id' | 'title'>>>
      }
  >
}

export type GetLiveVideoStatusQueryVariables = {
  id: Scalars['String']
}

export type GetLiveVideoStatusQuery = { __typename?: 'Query' } & Pick<Query, 'getLiveVideoStatus'>

export type LiveInfoDataFragment = ({ __typename?: 'Video' } & Pick<Video, '_id' | 'published'> & {
    liveInfo: Maybe<
      { __typename?: 'LiveInfo' } & Pick<
        LiveInfo,
        'ingestURL' | 'streamKey' | 'ingestUser' | 'ingestPassword'
      >
    >
  }) &
  VideoPlayerDataFragment

export type VideoActionsFragment = { __typename?: 'Video' } & Pick<Video, 'published' | 'unlisted'>

export type GetVideoEditChannelsQueryVariables = {}

export type GetVideoEditChannelsQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<{ __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'>>
}

export type VideoEditDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'summary' | 'publishedAt' | 'streamUrl' | 'largeImage'
> & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id'> }

export type VideoInfoDataFragment = { __typename?: 'Video' } & Pick<
  Video,
  '_id' | 'title' | 'summary' | 'live' | 'playCount' | 'publishedAt' | 'createdAt'
> & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'> }

export type VideoTagsDataFragment = { __typename?: 'Video' } & {
  tags: Array<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name'>>
}

export type VideoViewDataFragment = ({ __typename?: 'Video' } & Pick<Video, '_id'> & {
    channel: { __typename?: 'Channel' } & Pick<Channel, '_id'>
  }) &
  LiveInfoDataFragment &
  VideoPlayerDataFragment &
  VideoInfoDataFragment &
  VideoTagsDataFragment &
  VideoActionsFragment &
  VideoEditDataFragment

export type LoginAdminMutationVariables = {
  email: Scalars['String']
  password: Scalars['String']
}

export type LoginAdminMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginAdmin'>

export type CreateVideoMutationVariables = {
  video: VideoInput
}

export type CreateVideoMutation = { __typename?: 'Mutation' } & {
  createVideo: { __typename?: 'Video' } & Pick<
    Video,
    | '_id'
    | 'title'
    | 'summary'
    | 'published'
    | 'unlisted'
    | 'live'
    | 'playCount'
    | 'largeImage'
    | 'directUrl'
    | 'createdAt'
    | 'publishedAt'
    | 'liveStreamUrl'
    | 'streamUrl'
    | 'embedUrl'
  > & { channel: { __typename?: 'Channel' } & Pick<Channel, '_id' | 'title'> }
}

export type GetAdminActionsPageQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
}

export type GetAdminActionsPageQuery = { __typename?: 'Query' } & {
  getAdminActions: Array<
    { __typename?: 'AdminAction' } & Pick<AdminAction, '_id' | 'type' | 'title' | 'createdAt'> & {
        adminUser: { __typename?: 'AdminUser' } & Pick<AdminUser, '_id' | 'email'>
      }
  >
}

export type GetAdminUsersPageQueryVariables = {}

export type GetAdminUsersPageQuery = { __typename?: 'Query' } & {
  getAdminUsers: Array<{ __typename?: 'AdminUser' } & AdminUserListDataFragment>
}

export type SetAdminUserPermissionsMutationVariables = {
  id: Scalars['String']
  roles: Array<Roles>
  channels: Array<Scalars['String']>
}

export type SetAdminUserPermissionsMutation = { __typename?: 'Mutation' } & {
  setAdminUserPermissions: { __typename?: 'AdminUser' } & AdminUserListDataFragment
}

export type SuspendAdminUserMutationVariables = {
  id: Scalars['String']
}

export type SuspendAdminUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'suspendAdminUser'
>

export type CreateAdminUserMutationVariables = {
  email: Scalars['String']
  password: Scalars['String']
}

export type CreateAdminUserMutation = { __typename?: 'Mutation' } & {
  signUpAdminUser: { __typename?: 'AdminUser' } & AdminUserListDataFragment
}

export type GetChannelPageQueryVariables = {
  id: Scalars['String']
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type GetChannelPageQuery = { __typename?: 'Query' } & Pick<Query, 'videoPageLimit'> & {
    getAdminChannel: ({ __typename?: 'Channel' } & Pick<Channel, '_id'>) &
      ChannelViewDataFragment &
      ChannelEditDataFragment
  }

export type UpdateChannelPlaylistsOrderMutationVariables = {
  id: Scalars['String']
  order: Array<Scalars['String']>
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type UpdateChannelPlaylistsOrderMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'videoPageLimit'
> & {
    updateChannelPlaylistsOrder: ({ __typename?: 'Channel' } & Pick<Channel, '_id'>) &
      ChannelViewDataFragment &
      ChannelEditDataFragment
  }

export type UpdateChannelMutationVariables = {
  id: Scalars['String']
  channel: UpdateChannelInput
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type UpdateChannelMutation = { __typename?: 'Mutation' } & {
  updateChannel: { __typename?: 'Channel' } & ChannelViewDataFragment & ChannelEditDataFragment
}

export type RemoveChannelMutationVariables = {
  id: Scalars['String']
}

export type RemoveChannelMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeChannel'>

export type RemoveChannelFeaturedVideoMutationVariables = {
  id: Scalars['String']
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type RemoveChannelFeaturedVideoMutation = { __typename?: 'Mutation' } & {
  removeChannelFeaturedVideo: { __typename?: 'Channel' } & ChannelViewDataFragment &
    ChannelEditDataFragment
}

export type RemoveChannelLiveStreamVideoMutationVariables = {
  id: Scalars['String']
  includeLive?: Maybe<Scalars['Boolean']>
  includeUnlisted?: Maybe<Scalars['Boolean']>
  includeUnpublished?: Maybe<Scalars['Boolean']>
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type RemoveChannelLiveStreamVideoMutation = { __typename?: 'Mutation' } & {
  removeChannelLiveStreamVideo: { __typename?: 'Channel' } & ChannelViewDataFragment &
    ChannelEditDataFragment
}

export type GetChannelsPageQueryVariables = {
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
}

export type GetChannelsPageQuery = { __typename?: 'Query' } & {
  getAdminChannels: Array<
    { __typename?: 'Channel' } & ChannelListItemDataFragment & ChannelTableDataFragment
  >
}

export type CreateChannelMutationVariables = {
  channel: ChannelInput
  channelVideoLimit?: Maybe<Scalars['Float']>
  channelVideoOffset?: Maybe<Scalars['Float']>
}

export type CreateChannelMutation = { __typename?: 'Mutation' } & {
  createChannel: { __typename?: 'Channel' } & ChannelListItemDataFragment & ChannelTableDataFragment
}

export type GetAllCommentsQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetAllCommentsQuery = { __typename?: 'Query' } & Pick<Query, 'commentPageLimit'> & {
    getAllComments: Array<{ __typename?: 'Comment' } & CommentTabDataFragment>
  }

export type GetReportedCommentsQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetReportedCommentsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'commentPageLimit'
> & {
    getReportedComments: Array<
      ({ __typename?: 'Comment' } & Pick<Comment, 'updatedAt'>) & CommentTabDataFragment
    >
  }

export type GetApprovedCommentsQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetApprovedCommentsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'commentPageLimit'
> & { getApprovedComments: Array<{ __typename?: 'Comment' } & CommentTabDataFragment> }

export type GetRejectedCommentsQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetRejectedCommentsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'commentPageLimit'
> & {
    getRejectedComments: Array<
      ({ __typename?: 'Comment' } & Pick<Comment, 'bannedFromComment' | 'duplicate'>) &
        CommentTabDataFragment
    >
  }

export type GetUnfilteredCommentsQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  query?: Maybe<Scalars['String']>
}

export type GetUnfilteredCommentsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'commentPageLimit'
> & { getUnfilteredComments: Array<{ __typename?: 'Comment' } & CommentTabDataFragment> }

export type GetAllCommentsAmountSinceQueryVariables = {
  since: Scalars['DateTime']
}

export type GetAllCommentsAmountSinceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getAllCommentsAmountSince'
>

export type GetReportedCommentsAmountSinceQueryVariables = {
  since: Scalars['DateTime']
}

export type GetReportedCommentsAmountSinceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getReportedCommentsAmountSince'
>

export type GetApprovedCommentsAmountSinceQueryVariables = {
  since: Scalars['DateTime']
}

export type GetApprovedCommentsAmountSinceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getApprovedCommentsAmountSince'
>

export type GetRejectedCommentsAmountSinceQueryVariables = {
  since: Scalars['DateTime']
}

export type GetRejectedCommentsAmountSinceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getRejectedCommentsAmountSince'
>

export type GetUnfilteredCommentsAmountSinceQueryVariables = {
  since: Scalars['DateTime']
}

export type GetUnfilteredCommentsAmountSinceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getUnfilteredCommentsAmountSince'
>

export type ApproveCommentMutationVariables = {
  id: Scalars['String']
}

export type ApproveCommentMutation = { __typename?: 'Mutation' } & {
  approveComment: { __typename?: 'Comment' } & CommentTabDataFragment
}

export type RejectCommentMutationVariables = {
  id: Scalars['String']
}

export type RejectCommentMutation = { __typename?: 'Mutation' } & {
  rejectComment: { __typename?: 'Comment' } & CommentTabDataFragment
}

export type ForgotPasswordAdminMutationVariables = {
  email: Scalars['String']
}

export type ForgotPasswordAdminMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forgotPasswordAdmin'
>

export type ResetPasswordAdminTokenMutationVariables = {
  token: Scalars['String']
  newPassword: Scalars['String']
  newPasswordConfirmed: Scalars['String']
}

export type ResetPasswordAdminTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPasswordAdminToken'
>

export type GetActiveHomeConfigQueryVariables = {}

export type GetActiveHomeConfigQuery = { __typename?: 'Query' } & {
  adminGetActiveConfig: { __typename?: 'Config' } & Pick<Config, '_id' | 'title'> & {
      featuredVideos: Array<
        { __typename?: 'Video' } & VideoTableDataFragment & VideoCardDataFragment
      >
      navigationChannels: Maybe<
        Array<Maybe<{ __typename?: 'Channel' } & PageItemsListDataFragment>>
      >
    }
}

export type UpdateConfigMutationVariables = {
  id: Scalars['String']
  config: UpdateConfigInput
}

export type UpdateConfigMutation = { __typename?: 'Mutation' } & {
  updateConfig: { __typename?: 'Config' } & Pick<Config, '_id'>
}

export type GetPlaylistPageQueryVariables = {
  id: Scalars['String']
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type GetPlaylistPageQuery = { __typename?: 'Query' } & Pick<Query, 'videoPageLimit'> & {
    getAdminPlaylist: { __typename?: 'Playlist' } & PlaylistListItemDataFragment
  }

export type GetPlaylistVideoIdsQueryVariables = {
  id: Scalars['String']
}

export type GetPlaylistVideoIdsQuery = { __typename?: 'Query' } & {
  getAdminPlaylist: { __typename?: 'Playlist' } & Pick<Playlist, '_id' | 'videoIds'>
}

export type UpdatePlaylistMutationVariables = {
  id: Scalars['String']
  playlist: UpdatePlaylistInput
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type UpdatePlaylistMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'videoPageLimit'
> & { updatePlaylist: { __typename?: 'Playlist' } & PlaylistListItemDataFragment }

export type RemovePlaylistMutationVariables = {
  id: Scalars['String']
}

export type RemovePlaylistMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removePlaylist'>

export type GetPlaylistsPageQueryVariables = {
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type GetPlaylistsPageQuery = { __typename?: 'Query' } & {
  getAdminPlaylists: Array<
    { __typename?: 'Playlist' } & PlaylistListItemDataFragment & PlaylistTableDataFragment
  >
}

export type CreatePlaylistMutationVariables = {
  playlist: PlaylistInput
  playlistVideoLimit?: Maybe<Scalars['Float']>
  playlistVideoOffset?: Maybe<Scalars['Float']>
}

export type CreatePlaylistMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'videoPageLimit'
> & {
    createPlaylist: { __typename?: 'Playlist' } & PlaylistListItemDataFragment &
      PlaylistTableDataFragment
  }

export type GetSalesQueryVariables = {}

export type GetSalesQuery = { __typename?: 'Query' } & {
  getSales: Array<{ __typename?: 'Sale' } & SalesTextListDataFragment>
}

export type CreateSaleMutationVariables = {
  text: Scalars['String']
  description: Scalars['String']
  url: Scalars['String']
  videoUploadId: Scalars['String']
  textSale: Scalars['String']
}

export type CreateSaleMutation = { __typename?: 'Mutation' } & {
  createSale: { __typename?: 'Sale' } & SalesTextListDataFragment
}

export type UpdateSaleMutationVariables = {
  id: Scalars['String']
  text: Scalars['String']
  description: Scalars['String']
  url: Scalars['String']
  textSale: Scalars['String']
}

export type UpdateSaleMutation = { __typename?: 'Mutation' } & {
  updateSale: { __typename?: 'Sale' } & SalesTextListDataFragment
}

export type RemoveSaleMutationVariables = {
  id: Scalars['String']
}

export type RemoveSaleMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeSale'>

export type ToggleHiddenMutationVariables = {
  id: Scalars['String']
  isChecked: Scalars['Boolean']
}

export type ToggleHiddenMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'toggleHidden'>

export type ResetSaleClicksMutationVariables = {
  id: Scalars['String']
}

export type ResetSaleClicksMutation = { __typename?: 'Mutation' } & {
  resetSaleClicks: { __typename?: 'Sale' } & SalesTextListDataFragment
}

export type ResetAllSaleClicksMutationVariables = {}

export type ResetAllSaleClicksMutation = { __typename?: 'Mutation' } & {
  resetAllSaleClicks: Array<{ __typename?: 'Sale' } & SalesTextListDataFragment>
}

export type GetScheduledVideosQueryVariables = {}

export type GetScheduledVideosQuery = { __typename?: 'Query' } & {
  getScheduledVideos: Array<{ __typename?: 'Video' } & ScheduledVideoTableDataFragment>
}

export type GetUserQueryVariables = {
  username?: Maybe<Scalars['String']>
}

export type GetUserQuery = { __typename?: 'Query' } & {
  user: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'commentBan' | 'createdAt'>>
}

export type GetUserCommentsQueryVariables = {
  id: Scalars['String']
  limit?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
}

export type GetUserCommentsQuery = { __typename?: 'Query' } & Pick<Query, 'commentPageLimit'> & {
    getUserComments: Array<{ __typename?: 'Comment' } & CommentListDataFragment>
  }

export type ToggleUserCommentBanMutationVariables = {
  id: Scalars['String']
}

export type ToggleUserCommentBanMutation = { __typename?: 'Mutation' } & {
  toggleUserCommentBan: { __typename?: 'User' } & Pick<User, '_id' | 'commentBan'>
}

export type GetVideoPageQueryVariables = {
  id: Scalars['String']
}

export type GetVideoPageQuery = { __typename?: 'Query' } & {
  getVideo: ({ __typename?: 'Video' } & Pick<Video, '_id' | 'audio'> & {
      playlists: Array<{ __typename?: 'Playlist' } & PlaylistCardDataFragment>
    }) &
    VideoViewDataFragment
}

export type UpdateVideoMutationVariables = {
  id: Scalars['String']
  video: UpdateVideoInput
  create?: Maybe<Scalars['Boolean']>
}

export type UpdateVideoMutation = { __typename?: 'Mutation' } & {
  updateVideo: ({ __typename?: 'Video' } & Pick<Video, '_id'>) & VideoViewDataFragment
}

export type RemoveVideoMutationVariables = {
  id: Scalars['String']
}

export type RemoveVideoMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeVideo'>

export type AddVideosToPlaylistMutationVariables = {
  id: Scalars['String']
  videoIds: Array<Scalars['String']>
}

export type AddVideosToPlaylistMutation = { __typename?: 'Mutation' } & {
  addVideosToPlaylist: { __typename?: 'Playlist' } & PlaylistCardDataFragment
}
export const AdminUserPermissionModalDataFragmentDoc = gql`
  fragment AdminUserPermissionModalData on AdminUser {
    _id
    email
    roles
    collaboratingChannels {
      _id
    }
  }
`
export const AdminUserListDataFragmentDoc = gql`
  fragment AdminUserListData on AdminUser {
    _id
    email
    ...AdminUserPermissionModalData
  }
  ${AdminUserPermissionModalDataFragmentDoc}
`
export const ChannelCardDataFragmentDoc = gql`
  fragment ChannelCardData on Channel {
    _id
    title
    totalVideoViews
    createdAt
  }
`
export const ChannelEditDataFragmentDoc = gql`
  fragment ChannelEditData on Channel {
    _id
    title
    summary
    avatar
    coverImage
    alwaysLive
    dayTimesLive {
      sun
      mon
      tue
      wed
      thu
      fri
      sat
    }
    showInfo {
      times
      phone
    }
    featuredVideo {
      _id
    }
    liveStreamVideo {
      _id
    }
    links {
      website
      facebook
      twitter
      gab
      minds
      subscribeStar
    }
  }
`
export const VideoCardDataFragmentDoc = gql`
  fragment VideoCardData on Video {
    _id
    title
    largeImage
    published
    unlisted
    live
    playCount
    createdAt
    channel {
      _id
    }
  }
`
export const ChannelListItemDataFragmentDoc = gql`
  fragment ChannelListItemData on Channel {
    _id
    title
    summary
    videos(limit: $channelVideoLimit, offset: $channelVideoOffset) {
      ...VideoCardData
    }
  }
  ${VideoCardDataFragmentDoc}
`
export const ChannelTableDataFragmentDoc = gql`
  fragment ChannelTableData on Channel {
    _id
    title
    totalVideoViews
    totalVideos
    totalPlaylists
    createdAt
  }
`
export const ChannelInfoDataFragmentDoc = gql`
  fragment ChannelInfoData on Channel {
    _id
    title
    summary
    links {
      website
      facebook
      telegram
      twitter
      gab
      minds
    }
  }
`
export const VideoTableDataFragmentDoc = gql`
  fragment VideoTableData on Video {
    _id
    title
    playCount
    published
    createdAt
    channel {
      _id
    }
  }
`
export const ChannelLiveStreamVideoDataFragmentDoc = gql`
  fragment ChannelLiveStreamVideoData on Channel {
    _id
    liveStreamVideo {
      ...VideoCardData
      ...VideoTableData
    }
  }
  ${VideoCardDataFragmentDoc}
  ${VideoTableDataFragmentDoc}
`
export const ChannelFeaturedVideoDataFragmentDoc = gql`
  fragment ChannelFeaturedVideoData on Channel {
    _id
    featuredVideo {
      ...VideoCardData
      ...VideoTableData
    }
  }
  ${VideoCardDataFragmentDoc}
  ${VideoTableDataFragmentDoc}
`
export const PlaylistListItemDataFragmentDoc = gql`
  fragment PlaylistListItemData on Playlist {
    _id
    title
    summary
    isGlobal
    channel {
      _id
      title
    }
    videos(limit: $playlistVideoLimit, offset: $playlistVideoOffset) {
      ...VideoTableData
      ...VideoCardData
    }
  }
  ${VideoTableDataFragmentDoc}
  ${VideoCardDataFragmentDoc}
`
export const PlaylistTableDataFragmentDoc = gql`
  fragment PlaylistTableData on Playlist {
    _id
    title
    totalVideos
    totalVideoViews
    isPublic
    createdAt
    isGlobal
    channel {
      _id
      title
    }
  }
`
export const ChannelPlaylistsDataFragmentDoc = gql`
  fragment ChannelPlaylistsData on Channel {
    _id
    playlists {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistListItemDataFragmentDoc}
  ${PlaylistTableDataFragmentDoc}
`
export const ChannelVideosDataFragmentDoc = gql`
  fragment ChannelVideosData on Channel {
    _id
    videos(
      limit: $channelVideoLimit
      offset: $channelVideoOffset
      includeLive: $includeLive
      includeUnpublished: $includeUnpublished
      includeUnlisted: $includeUnlisted
    ) {
      ...VideoCardData
      ...VideoTableData
    }
  }
  ${VideoCardDataFragmentDoc}
  ${VideoTableDataFragmentDoc}
`
export const ChannelViewDataFragmentDoc = gql`
  fragment ChannelViewData on Channel {
    _id
    ...ChannelInfoData
    ...ChannelLiveStreamVideoData
    ...ChannelFeaturedVideoData
    ...ChannelPlaylistsData
    ...ChannelVideosData
  }
  ${ChannelInfoDataFragmentDoc}
  ${ChannelLiveStreamVideoDataFragmentDoc}
  ${ChannelFeaturedVideoDataFragmentDoc}
  ${ChannelPlaylistsDataFragmentDoc}
  ${ChannelVideosDataFragmentDoc}
`
export const CommentContentDataFragmentDoc = gql`
  fragment CommentContentData on Comment {
    _id
    content
    createdAt
    replyTo {
      _id
    }
    reports {
      content
    }
    video {
      _id
      title
      channel {
        _id
      }
    }
    user {
      username
    }
  }
`
export const CommentModerationCardDataFragmentDoc = gql`
  fragment CommentModerationCardData on Comment {
    _id
    spam
    reported
    approved
    rejected
    bannedFromComment
    rejectedByUploader
    duplicate
    ...CommentContentData
  }
  ${CommentContentDataFragmentDoc}
`
export const CommentListDataFragmentDoc = gql`
  fragment CommentListData on Comment {
    _id
    ...CommentContentData
    ...CommentModerationCardData
  }
  ${CommentContentDataFragmentDoc}
  ${CommentModerationCardDataFragmentDoc}
`
export const CommentTabDataFragmentDoc = gql`
  fragment CommentTabData on Comment {
    _id
    ...CommentModerationCardData
    createdAt
  }
  ${CommentModerationCardDataFragmentDoc}
`
export const DraggableChannelListFragmentDoc = gql`
  fragment DraggableChannelList on Channel {
    _id
    title
  }
`
export const DraggbleVideoListDataFragmentDoc = gql`
  fragment DraggbleVideoListData on Video {
    _id
    title
    largeImage
  }
`
export const ChannelFieldsFragmentDoc = gql`
  fragment ChannelFields on Channel {
    _id
    title
  }
`
export const ChannelFieldsExtendedFragmentDoc = gql`
  fragment ChannelFieldsExtended on Channel {
    _id
    title
  }
`
export const PlaylistFieldsFragmentDoc = gql`
  fragment PlaylistFields on Playlist {
    _id
    title
    channel {
      _id
      title
    }
  }
`
export const PageItemsListDataFragmentDoc = gql`
  fragment PageItemsListData on Channel {
    _id
    title
  }
`
export const PlaylistCardDataFragmentDoc = gql`
  fragment PlaylistCardData on Playlist {
    _id
    title
    isGlobal
    channel {
      _id
      title
    }
    totalVideoViews
    createdAt
  }
`
export const SalesTextListDataFragmentDoc = gql`
  fragment SalesTextListData on Sale {
    _id
    text
    textSale
    description
    url
    videoUpload {
      cloudflareVideoUID
      key
    }
    isHidden
    clickRate
    clicks
    amountServed
    createdAt
  }
`
export const ScheduledVideoTableDataFragmentDoc = gql`
  fragment ScheduledVideoTableData on Video {
    _id
    title
    publishedAt
    createdAt
    channel {
      _id
      title
    }
  }
`
export const VideoPickerFiltersPropsDataFragmentDoc = gql`
  fragment VideoPickerFiltersPropsData on Channel {
    _id
    title
  }
`
export const VideoPlayerDataFragmentDoc = gql`
  fragment VideoPlayerData on Video {
    _id
    streamUrl
  }
`
export const LiveInfoDataFragmentDoc = gql`
  fragment LiveInfoData on Video {
    _id
    published
    liveInfo {
      ingestURL
      streamKey
      ingestUser
      ingestPassword
    }
    ...VideoPlayerData
  }
  ${VideoPlayerDataFragmentDoc}
`
export const VideoInfoDataFragmentDoc = gql`
  fragment VideoInfoData on Video {
    _id
    title
    summary
    live
    playCount
    publishedAt
    channel {
      _id
      title
    }
    createdAt
  }
`
export const VideoTagsDataFragmentDoc = gql`
  fragment VideoTagsData on Video {
    tags {
      _id
      name
    }
  }
`
export const VideoActionsFragmentDoc = gql`
  fragment VideoActions on Video {
    published
    unlisted
  }
`
export const VideoEditDataFragmentDoc = gql`
  fragment VideoEditData on Video {
    _id
    title
    summary
    channel {
      _id
    }
    publishedAt
    streamUrl
    largeImage
  }
`
export const VideoViewDataFragmentDoc = gql`
  fragment VideoViewData on Video {
    _id
    channel {
      _id
    }
    ...LiveInfoData
    ...VideoPlayerData
    ...VideoInfoData
    ...VideoTagsData
    ...VideoActions
    ...VideoEditData
  }
  ${LiveInfoDataFragmentDoc}
  ${VideoPlayerDataFragmentDoc}
  ${VideoInfoDataFragmentDoc}
  ${VideoTagsDataFragmentDoc}
  ${VideoActionsFragmentDoc}
  ${VideoEditDataFragmentDoc}
`
export const GetAdminUserPermissionModalChannelsDocument = gql`
  query GetAdminUserPermissionModalChannels {
    getAdminChannels {
      _id
      title
    }
  }
`

export function useGetAdminUserPermissionModalChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminUserPermissionModalChannelsQuery,
    GetAdminUserPermissionModalChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAdminUserPermissionModalChannelsQuery,
    GetAdminUserPermissionModalChannelsQueryVariables
  >(GetAdminUserPermissionModalChannelsDocument, baseOptions)
}
export function useGetAdminUserPermissionModalChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminUserPermissionModalChannelsQuery,
    GetAdminUserPermissionModalChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAdminUserPermissionModalChannelsQuery,
    GetAdminUserPermissionModalChannelsQueryVariables
  >(GetAdminUserPermissionModalChannelsDocument, baseOptions)
}

export type GetAdminUserPermissionModalChannelsQueryHookResult = ReturnType<
  typeof useGetAdminUserPermissionModalChannelsQuery
>
export type GetAdminUserPermissionModalChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetAdminUserPermissionModalChannelsQuery,
  GetAdminUserPermissionModalChannelsQueryVariables
>
export const ResetPasswordAdminDocument = gql`
  mutation ResetPasswordAdmin(
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirmed: String!
  ) {
    resetPasswordAdmin(
      currentPassword: $currentPassword
      newPassword: $newPassword
      newPasswordConfirmed: $newPasswordConfirmed
    )
  }
`
export type ResetPasswordAdminMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordAdminMutation,
  ResetPasswordAdminMutationVariables
>

export function useResetPasswordAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordAdminMutation,
    ResetPasswordAdminMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordAdminMutation,
    ResetPasswordAdminMutationVariables
  >(ResetPasswordAdminDocument, baseOptions)
}
export type ResetPasswordAdminMutationHookResult = ReturnType<typeof useResetPasswordAdminMutation>
export type ResetPasswordAdminMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordAdminMutation
>
export type ResetPasswordAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordAdminMutation,
  ResetPasswordAdminMutationVariables
>
export const GetChannelPickerDocument = gql`
  query GetChannelPicker {
    getAdminChannels {
      ...ChannelTableData
      ...ChannelCardData
    }
  }
  ${ChannelTableDataFragmentDoc}
  ${ChannelCardDataFragmentDoc}
`

export function useGetChannelPickerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetChannelPickerQuery,
    GetChannelPickerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetChannelPickerQuery, GetChannelPickerQueryVariables>(
    GetChannelPickerDocument,
    baseOptions
  )
}
export function useGetChannelPickerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChannelPickerQuery,
    GetChannelPickerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetChannelPickerQuery, GetChannelPickerQueryVariables>(
    GetChannelPickerDocument,
    baseOptions
  )
}

export type GetChannelPickerQueryHookResult = ReturnType<typeof useGetChannelPickerQuery>
export type GetChannelPickerQueryResult = ApolloReactCommon.QueryResult<
  GetChannelPickerQuery,
  GetChannelPickerQueryVariables
>
export const GetCommentReportsDocument = gql`
  query GetCommentReports($id: String!) {
    getCommentReports(id: $id) {
      id
      type
      content
    }
  }
`

export function useGetCommentReportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCommentReportsQuery,
    GetCommentReportsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCommentReportsQuery, GetCommentReportsQueryVariables>(
    GetCommentReportsDocument,
    baseOptions
  )
}
export function useGetCommentReportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCommentReportsQuery,
    GetCommentReportsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCommentReportsQuery, GetCommentReportsQueryVariables>(
    GetCommentReportsDocument,
    baseOptions
  )
}

export type GetCommentReportsQueryHookResult = ReturnType<typeof useGetCommentReportsQuery>
export type GetCommentReportsQueryResult = ApolloReactCommon.QueryResult<
  GetCommentReportsQuery,
  GetCommentReportsQueryVariables
>
export const GetHomeEditDocument = gql`
  query GetHomeEdit($videoIds: [String!]!) {
    getVideos(ids: $videoIds) {
      ...DraggbleVideoListData
    }
    getAdminChannels {
      ...DraggableChannelList
    }
  }
  ${DraggbleVideoListDataFragmentDoc}
  ${DraggableChannelListFragmentDoc}
`

export function useGetHomeEditQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeEditQuery, GetHomeEditQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetHomeEditQuery, GetHomeEditQueryVariables>(
    GetHomeEditDocument,
    baseOptions
  )
}
export function useGetHomeEditLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeEditQuery, GetHomeEditQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetHomeEditQuery, GetHomeEditQueryVariables>(
    GetHomeEditDocument,
    baseOptions
  )
}

export type GetHomeEditQueryHookResult = ReturnType<typeof useGetHomeEditQuery>
export type GetHomeEditQueryResult = ApolloReactCommon.QueryResult<
  GetHomeEditQuery,
  GetHomeEditQueryVariables
>
export const GetLiveVideoUploadChannelsDocument = gql`
  query GetLiveVideoUploadChannels {
    getAdminChannels {
      _id
      title
      playlists {
        _id
        title
      }
    }
  }
`

export function useGetLiveVideoUploadChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLiveVideoUploadChannelsQuery,
    GetLiveVideoUploadChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetLiveVideoUploadChannelsQuery,
    GetLiveVideoUploadChannelsQueryVariables
  >(GetLiveVideoUploadChannelsDocument, baseOptions)
}
export function useGetLiveVideoUploadChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLiveVideoUploadChannelsQuery,
    GetLiveVideoUploadChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLiveVideoUploadChannelsQuery,
    GetLiveVideoUploadChannelsQueryVariables
  >(GetLiveVideoUploadChannelsDocument, baseOptions)
}

export type GetLiveVideoUploadChannelsQueryHookResult = ReturnType<
  typeof useGetLiveVideoUploadChannelsQuery
>
export type GetLiveVideoUploadChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetLiveVideoUploadChannelsQuery,
  GetLiveVideoUploadChannelsQueryVariables
>
export const GetChannelFieldsDocument = gql`
  query GetChannelFields($id: String!) {
    getAdminChannel(id: $id) {
      ...ChannelFields
    }
  }
  ${ChannelFieldsFragmentDoc}
`

export function useGetChannelFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetChannelFieldsQuery,
    GetChannelFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetChannelFieldsQuery, GetChannelFieldsQueryVariables>(
    GetChannelFieldsDocument,
    baseOptions
  )
}
export function useGetChannelFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChannelFieldsQuery,
    GetChannelFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetChannelFieldsQuery, GetChannelFieldsQueryVariables>(
    GetChannelFieldsDocument,
    baseOptions
  )
}

export type GetChannelFieldsQueryHookResult = ReturnType<typeof useGetChannelFieldsQuery>
export type GetChannelFieldsQueryResult = ApolloReactCommon.QueryResult<
  GetChannelFieldsQuery,
  GetChannelFieldsQueryVariables
>
export const GetPlaylistFieldsDocument = gql`
  query GetPlaylistFields($id: String!) {
    getAdminPlaylist(id: $id) {
      ...PlaylistFields
    }
  }
  ${PlaylistFieldsFragmentDoc}
`

export function useGetPlaylistFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistFieldsQuery,
    GetPlaylistFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPlaylistFieldsQuery, GetPlaylistFieldsQueryVariables>(
    GetPlaylistFieldsDocument,
    baseOptions
  )
}
export function useGetPlaylistFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistFieldsQuery,
    GetPlaylistFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPlaylistFieldsQuery, GetPlaylistFieldsQueryVariables>(
    GetPlaylistFieldsDocument,
    baseOptions
  )
}

export type GetPlaylistFieldsQueryHookResult = ReturnType<typeof useGetPlaylistFieldsQuery>
export type GetPlaylistFieldsQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistFieldsQuery,
  GetPlaylistFieldsQueryVariables
>
export const GetPlaylistAddChannelsDocument = gql`
  query GetPlaylistAddChannels {
    getAdminChannels {
      _id
      title
    }
  }
`

export function useGetPlaylistAddChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistAddChannelsQuery,
    GetPlaylistAddChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetPlaylistAddChannelsQuery,
    GetPlaylistAddChannelsQueryVariables
  >(GetPlaylistAddChannelsDocument, baseOptions)
}
export function useGetPlaylistAddChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistAddChannelsQuery,
    GetPlaylistAddChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetPlaylistAddChannelsQuery,
    GetPlaylistAddChannelsQueryVariables
  >(GetPlaylistAddChannelsDocument, baseOptions)
}

export type GetPlaylistAddChannelsQueryHookResult = ReturnType<
  typeof useGetPlaylistAddChannelsQuery
>
export type GetPlaylistAddChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistAddChannelsQuery,
  GetPlaylistAddChannelsQueryVariables
>
export const GetPlaylistPickerDataDocument = gql`
  query GetPlaylistPickerData {
    getAdminPlaylists {
      _id
      ...PlaylistCardData
      ...PlaylistTableData
    }
  }
  ${PlaylistCardDataFragmentDoc}
  ${PlaylistTableDataFragmentDoc}
`

export function useGetPlaylistPickerDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistPickerDataQuery,
    GetPlaylistPickerDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPlaylistPickerDataQuery, GetPlaylistPickerDataQueryVariables>(
    GetPlaylistPickerDataDocument,
    baseOptions
  )
}
export function useGetPlaylistPickerDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistPickerDataQuery,
    GetPlaylistPickerDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetPlaylistPickerDataQuery,
    GetPlaylistPickerDataQueryVariables
  >(GetPlaylistPickerDataDocument, baseOptions)
}

export type GetPlaylistPickerDataQueryHookResult = ReturnType<typeof useGetPlaylistPickerDataQuery>
export type GetPlaylistPickerDataQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistPickerDataQuery,
  GetPlaylistPickerDataQueryVariables
>
export const GetHeaderSaleActiveDocument = gql`
  query GetHeaderSaleActive {
    adminGetActiveConfig {
      _id
      headerSaleActive
    }
  }
`

export function useGetHeaderSaleActiveQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHeaderSaleActiveQuery,
    GetHeaderSaleActiveQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetHeaderSaleActiveQuery, GetHeaderSaleActiveQueryVariables>(
    GetHeaderSaleActiveDocument,
    baseOptions
  )
}
export function useGetHeaderSaleActiveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHeaderSaleActiveQuery,
    GetHeaderSaleActiveQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetHeaderSaleActiveQuery, GetHeaderSaleActiveQueryVariables>(
    GetHeaderSaleActiveDocument,
    baseOptions
  )
}

export type GetHeaderSaleActiveQueryHookResult = ReturnType<typeof useGetHeaderSaleActiveQuery>
export type GetHeaderSaleActiveQueryResult = ApolloReactCommon.QueryResult<
  GetHeaderSaleActiveQuery,
  GetHeaderSaleActiveQueryVariables
>
export const UpdateHeaderSaleDocument = gql`
  mutation UpdateHeaderSale($id: String!, $config: UpdateConfigInput!) {
    updateConfig(id: $id, config: $config) {
      _id
      headerSaleActive
    }
  }
`
export type UpdateHeaderSaleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateHeaderSaleMutation,
  UpdateHeaderSaleMutationVariables
>

export function useUpdateHeaderSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHeaderSaleMutation,
    UpdateHeaderSaleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateHeaderSaleMutation, UpdateHeaderSaleMutationVariables>(
    UpdateHeaderSaleDocument,
    baseOptions
  )
}
export type UpdateHeaderSaleMutationHookResult = ReturnType<typeof useUpdateHeaderSaleMutation>
export type UpdateHeaderSaleMutationResult = ApolloReactCommon.MutationResult<
  UpdateHeaderSaleMutation
>
export type UpdateHeaderSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateHeaderSaleMutation,
  UpdateHeaderSaleMutationVariables
>
export const SearchDocument = gql`
  query Search($query: String!, $category: String!, $limit: Float, $offset: Float) {
    search(query: $query, category: $category, limit: $limit, offset: $offset) {
      videos {
        ...VideoCardData
        ...VideoTableData
      }
    }
  }
  ${VideoCardDataFragmentDoc}
  ${VideoTableDataFragmentDoc}
`

export function useSearchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions)
}
export function useSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    baseOptions
  )
}

export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>
export const GetTagsDocument = gql`
  query GetTags($searchQuery: String!) {
    getTags(searchQuery: $searchQuery) {
      _id
      name
    }
  }
`

export function useGetTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    baseOptions
  )
}
export function useGetTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    baseOptions
  )
}

export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>
export type GetTagsQueryResult = ApolloReactCommon.QueryResult<GetTagsQuery, GetTagsQueryVariables>
export const GetAllUsersDocument = gql`
  query GetAllUsers($limit: Float, $offset: Float, $query: String) {
    userPageLimit @client @export(as: "limit")
    getAllUsers(limit: $limit, offset: $offset, query: $query) {
      _id
      username
    }
  }
`

export function useGetAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  )
}
export function useGetAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  )
}

export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>
export type GetAllUsersQueryResult = ApolloReactCommon.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>
export const MeAdminDocument = gql`
  query MeAdmin {
    meAdmin {
      _id
      email
      isAdmin
      displayName
      roles
      collaboratingChannels {
        _id
      }
    }
  }
`

export function useMeAdminQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeAdminQuery, MeAdminQueryVariables>
) {
  return ApolloReactHooks.useQuery<MeAdminQuery, MeAdminQueryVariables>(
    MeAdminDocument,
    baseOptions
  )
}
export function useMeAdminLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeAdminQuery, MeAdminQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<MeAdminQuery, MeAdminQueryVariables>(
    MeAdminDocument,
    baseOptions
  )
}

export type MeAdminQueryHookResult = ReturnType<typeof useMeAdminQuery>
export type MeAdminQueryResult = ApolloReactCommon.QueryResult<MeAdminQuery, MeAdminQueryVariables>
export const GetPlaylistsForVideoPageDocument = gql`
  query GetPlaylistsForVideoPage($limit: Float) {
    getAdminPlaylists(limit: $limit) {
      _id
      title
      channel {
        _id
      }
      isGlobal
    }
  }
`

export function useGetPlaylistsForVideoPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistsForVideoPageQuery,
    GetPlaylistsForVideoPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetPlaylistsForVideoPageQuery,
    GetPlaylistsForVideoPageQueryVariables
  >(GetPlaylistsForVideoPageDocument, baseOptions)
}
export function useGetPlaylistsForVideoPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistsForVideoPageQuery,
    GetPlaylistsForVideoPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetPlaylistsForVideoPageQuery,
    GetPlaylistsForVideoPageQueryVariables
  >(GetPlaylistsForVideoPageDocument, baseOptions)
}

export type GetPlaylistsForVideoPageQueryHookResult = ReturnType<
  typeof useGetPlaylistsForVideoPageQuery
>
export type GetPlaylistsForVideoPageQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistsForVideoPageQuery,
  GetPlaylistsForVideoPageQueryVariables
>
export const GetVideoCommentsDocument = gql`
  query GetVideoComments($id: String!, $limit: Float, $offset: Float) {
    commentPageLimit @client @export(as: "limit")
    getVideoComments(id: $id, limit: $limit, offset: $offset) {
      ...CommentListData
    }
  }
  ${CommentListDataFragmentDoc}
`

export function useGetVideoCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVideoCommentsQuery,
    GetVideoCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>(
    GetVideoCommentsDocument,
    baseOptions
  )
}
export function useGetVideoCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVideoCommentsQuery,
    GetVideoCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetVideoCommentsQuery, GetVideoCommentsQueryVariables>(
    GetVideoCommentsDocument,
    baseOptions
  )
}

export type GetVideoCommentsQueryHookResult = ReturnType<typeof useGetVideoCommentsQuery>
export type GetVideoCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetVideoCommentsQuery,
  GetVideoCommentsQueryVariables
>
export const GetAllVideoCommentsDocument = gql`
  query getAllVideoComments($id: String!, $limit: Float, $offset: Float) {
    commentPageLimit @client @export(as: "limit")
    getAllVideoComments(id: $id, limit: $limit, offset: $offset) {
      ...CommentModerationCardData
    }
  }
  ${CommentModerationCardDataFragmentDoc}
`

export function useGetAllVideoCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllVideoCommentsQuery,
    GetAllVideoCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllVideoCommentsQuery, GetAllVideoCommentsQueryVariables>(
    GetAllVideoCommentsDocument,
    baseOptions
  )
}
export function useGetAllVideoCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllVideoCommentsQuery,
    GetAllVideoCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllVideoCommentsQuery, GetAllVideoCommentsQueryVariables>(
    GetAllVideoCommentsDocument,
    baseOptions
  )
}

export type GetAllVideoCommentsQueryHookResult = ReturnType<typeof useGetAllVideoCommentsQuery>
export type GetAllVideoCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetAllVideoCommentsQuery,
  GetAllVideoCommentsQueryVariables
>
export const GetVideoPickerChannelsDocument = gql`
  query GetVideoPickerChannels {
    getAdminChannels {
      ...VideoPickerFiltersPropsData
    }
  }
  ${VideoPickerFiltersPropsDataFragmentDoc}
`

export function useGetVideoPickerChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVideoPickerChannelsQuery,
    GetVideoPickerChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetVideoPickerChannelsQuery,
    GetVideoPickerChannelsQueryVariables
  >(GetVideoPickerChannelsDocument, baseOptions)
}
export function useGetVideoPickerChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVideoPickerChannelsQuery,
    GetVideoPickerChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetVideoPickerChannelsQuery,
    GetVideoPickerChannelsQueryVariables
  >(GetVideoPickerChannelsDocument, baseOptions)
}

export type GetVideoPickerChannelsQueryHookResult = ReturnType<
  typeof useGetVideoPickerChannelsQuery
>
export type GetVideoPickerChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetVideoPickerChannelsQuery,
  GetVideoPickerChannelsQueryVariables
>
export const GetVideoPickerVideosDocument = gql`
  query GetVideoPickerVideos(
    $id: String!
    $limit: Float
    $offset: Float
    $includeLive: Boolean
    $includeUnpublished: Boolean
    $includeUnlisted: Boolean
  ) {
    videoPageLimit @client @export(as: "limit")
    getAdminChannel(id: $id) {
      _id
      totalVideos
      videos(
        limit: $limit
        offset: $offset
        includeLive: $includeLive
        includeUnpublished: $includeUnpublished
        includeUnlisted: $includeUnlisted
      ) {
        _id
        live
        ...VideoCardData
        ...VideoTableData
      }
    }
  }
  ${VideoCardDataFragmentDoc}
  ${VideoTableDataFragmentDoc}
`

export function useGetVideoPickerVideosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVideoPickerVideosQuery,
    GetVideoPickerVideosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetVideoPickerVideosQuery, GetVideoPickerVideosQueryVariables>(
    GetVideoPickerVideosDocument,
    baseOptions
  )
}
export function useGetVideoPickerVideosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVideoPickerVideosQuery,
    GetVideoPickerVideosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetVideoPickerVideosQuery,
    GetVideoPickerVideosQueryVariables
  >(GetVideoPickerVideosDocument, baseOptions)
}

export type GetVideoPickerVideosQueryHookResult = ReturnType<typeof useGetVideoPickerVideosQuery>
export type GetVideoPickerVideosQueryResult = ApolloReactCommon.QueryResult<
  GetVideoPickerVideosQuery,
  GetVideoPickerVideosQueryVariables
>
export const GetVideoUploadChannelsDocument = gql`
  query GetVideoUploadChannels {
    getAdminChannels {
      _id
      title
      playlists {
        _id
        title
      }
    }
  }
`

export function useGetVideoUploadChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVideoUploadChannelsQuery,
    GetVideoUploadChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetVideoUploadChannelsQuery,
    GetVideoUploadChannelsQueryVariables
  >(GetVideoUploadChannelsDocument, baseOptions)
}
export function useGetVideoUploadChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVideoUploadChannelsQuery,
    GetVideoUploadChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetVideoUploadChannelsQuery,
    GetVideoUploadChannelsQueryVariables
  >(GetVideoUploadChannelsDocument, baseOptions)
}

export type GetVideoUploadChannelsQueryHookResult = ReturnType<
  typeof useGetVideoUploadChannelsQuery
>
export type GetVideoUploadChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetVideoUploadChannelsQuery,
  GetVideoUploadChannelsQueryVariables
>
export const GetLiveVideoStatusDocument = gql`
  query GetLiveVideoStatus($id: String!) {
    getLiveVideoStatus(id: $id)
  }
`

export function useGetLiveVideoStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLiveVideoStatusQuery,
    GetLiveVideoStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetLiveVideoStatusQuery, GetLiveVideoStatusQueryVariables>(
    GetLiveVideoStatusDocument,
    baseOptions
  )
}
export function useGetLiveVideoStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLiveVideoStatusQuery,
    GetLiveVideoStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetLiveVideoStatusQuery, GetLiveVideoStatusQueryVariables>(
    GetLiveVideoStatusDocument,
    baseOptions
  )
}

export type GetLiveVideoStatusQueryHookResult = ReturnType<typeof useGetLiveVideoStatusQuery>
export type GetLiveVideoStatusQueryResult = ApolloReactCommon.QueryResult<
  GetLiveVideoStatusQuery,
  GetLiveVideoStatusQueryVariables
>
export const GetVideoEditChannelsDocument = gql`
  query GetVideoEditChannels {
    getAdminChannels {
      _id
      title
    }
  }
`

export function useGetVideoEditChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVideoEditChannelsQuery,
    GetVideoEditChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetVideoEditChannelsQuery, GetVideoEditChannelsQueryVariables>(
    GetVideoEditChannelsDocument,
    baseOptions
  )
}
export function useGetVideoEditChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetVideoEditChannelsQuery,
    GetVideoEditChannelsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetVideoEditChannelsQuery,
    GetVideoEditChannelsQueryVariables
  >(GetVideoEditChannelsDocument, baseOptions)
}

export type GetVideoEditChannelsQueryHookResult = ReturnType<typeof useGetVideoEditChannelsQuery>
export type GetVideoEditChannelsQueryResult = ApolloReactCommon.QueryResult<
  GetVideoEditChannelsQuery,
  GetVideoEditChannelsQueryVariables
>
export const LoginAdminDocument = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password)
  }
`
export type LoginAdminMutationFn = ApolloReactCommon.MutationFunction<
  LoginAdminMutation,
  LoginAdminMutationVariables
>

export function useLoginAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginAdminMutation,
    LoginAdminMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(
    LoginAdminDocument,
    baseOptions
  )
}
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>
export type LoginAdminMutationResult = ApolloReactCommon.MutationResult<LoginAdminMutation>
export type LoginAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginAdminMutation,
  LoginAdminMutationVariables
>
export const CreateVideoDocument = gql`
  mutation CreateVideo($video: VideoInput!) {
    createVideo(video: $video) {
      _id
      title
      summary
      published
      unlisted
      live
      channel {
        _id
        title
      }
      playCount
      largeImage
      directUrl
      createdAt
      publishedAt
      liveStreamUrl
      streamUrl
      embedUrl
    }
  }
`
export type CreateVideoMutationFn = ApolloReactCommon.MutationFunction<
  CreateVideoMutation,
  CreateVideoMutationVariables
>

export function useCreateVideoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateVideoMutation,
    CreateVideoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(
    CreateVideoDocument,
    baseOptions
  )
}
export type CreateVideoMutationHookResult = ReturnType<typeof useCreateVideoMutation>
export type CreateVideoMutationResult = ApolloReactCommon.MutationResult<CreateVideoMutation>
export type CreateVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateVideoMutation,
  CreateVideoMutationVariables
>
export const GetAdminActionsPageDocument = gql`
  query GetAdminActionsPage($limit: Float, $offset: Float) {
    getAdminActions(limit: $limit, offset: $offset) {
      _id
      type
      title
      adminUser {
        _id
        email
      }
      createdAt
    }
  }
`

export function useGetAdminActionsPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminActionsPageQuery,
    GetAdminActionsPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAdminActionsPageQuery, GetAdminActionsPageQueryVariables>(
    GetAdminActionsPageDocument,
    baseOptions
  )
}
export function useGetAdminActionsPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminActionsPageQuery,
    GetAdminActionsPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAdminActionsPageQuery, GetAdminActionsPageQueryVariables>(
    GetAdminActionsPageDocument,
    baseOptions
  )
}

export type GetAdminActionsPageQueryHookResult = ReturnType<typeof useGetAdminActionsPageQuery>
export type GetAdminActionsPageQueryResult = ApolloReactCommon.QueryResult<
  GetAdminActionsPageQuery,
  GetAdminActionsPageQueryVariables
>
export const GetAdminUsersPageDocument = gql`
  query GetAdminUsersPage {
    getAdminUsers {
      ...AdminUserListData
    }
  }
  ${AdminUserListDataFragmentDoc}
`

export function useGetAdminUsersPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminUsersPageQuery,
    GetAdminUsersPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAdminUsersPageQuery, GetAdminUsersPageQueryVariables>(
    GetAdminUsersPageDocument,
    baseOptions
  )
}
export function useGetAdminUsersPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminUsersPageQuery,
    GetAdminUsersPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAdminUsersPageQuery, GetAdminUsersPageQueryVariables>(
    GetAdminUsersPageDocument,
    baseOptions
  )
}

export type GetAdminUsersPageQueryHookResult = ReturnType<typeof useGetAdminUsersPageQuery>
export type GetAdminUsersPageQueryResult = ApolloReactCommon.QueryResult<
  GetAdminUsersPageQuery,
  GetAdminUsersPageQueryVariables
>
export const SetAdminUserPermissionsDocument = gql`
  mutation SetAdminUserPermissions($id: String!, $roles: [Roles!]!, $channels: [String!]!) {
    setAdminUserPermissions(id: $id, roles: $roles, channels: $channels) {
      ...AdminUserListData
    }
  }
  ${AdminUserListDataFragmentDoc}
`
export type SetAdminUserPermissionsMutationFn = ApolloReactCommon.MutationFunction<
  SetAdminUserPermissionsMutation,
  SetAdminUserPermissionsMutationVariables
>

export function useSetAdminUserPermissionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetAdminUserPermissionsMutation,
    SetAdminUserPermissionsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetAdminUserPermissionsMutation,
    SetAdminUserPermissionsMutationVariables
  >(SetAdminUserPermissionsDocument, baseOptions)
}
export type SetAdminUserPermissionsMutationHookResult = ReturnType<
  typeof useSetAdminUserPermissionsMutation
>
export type SetAdminUserPermissionsMutationResult = ApolloReactCommon.MutationResult<
  SetAdminUserPermissionsMutation
>
export type SetAdminUserPermissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetAdminUserPermissionsMutation,
  SetAdminUserPermissionsMutationVariables
>
export const SuspendAdminUserDocument = gql`
  mutation SuspendAdminUser($id: String!) {
    suspendAdminUser(id: $id)
  }
`
export type SuspendAdminUserMutationFn = ApolloReactCommon.MutationFunction<
  SuspendAdminUserMutation,
  SuspendAdminUserMutationVariables
>

export function useSuspendAdminUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SuspendAdminUserMutation,
    SuspendAdminUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SuspendAdminUserMutation, SuspendAdminUserMutationVariables>(
    SuspendAdminUserDocument,
    baseOptions
  )
}
export type SuspendAdminUserMutationHookResult = ReturnType<typeof useSuspendAdminUserMutation>
export type SuspendAdminUserMutationResult = ApolloReactCommon.MutationResult<
  SuspendAdminUserMutation
>
export type SuspendAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SuspendAdminUserMutation,
  SuspendAdminUserMutationVariables
>
export const CreateAdminUserDocument = gql`
  mutation CreateAdminUser($email: String!, $password: String!) {
    signUpAdminUser(email: $email, password: $password) {
      ...AdminUserListData
    }
  }
  ${AdminUserListDataFragmentDoc}
`
export type CreateAdminUserMutationFn = ApolloReactCommon.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>

export function useCreateAdminUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(
    CreateAdminUserDocument,
    baseOptions
  )
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>
export type CreateAdminUserMutationResult = ApolloReactCommon.MutationResult<
  CreateAdminUserMutation
>
export type CreateAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>
export const GetChannelPageDocument = gql`
  query GetChannelPage(
    $id: String!
    $includeLive: Boolean
    $includeUnpublished: Boolean
    $includeUnlisted: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "channelVideoLimit")
    getAdminChannel(id: $id) {
      _id
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelViewDataFragmentDoc}
  ${ChannelEditDataFragmentDoc}
`

export function useGetChannelPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetChannelPageQuery, GetChannelPageQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetChannelPageQuery, GetChannelPageQueryVariables>(
    GetChannelPageDocument,
    baseOptions
  )
}
export function useGetChannelPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChannelPageQuery,
    GetChannelPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetChannelPageQuery, GetChannelPageQueryVariables>(
    GetChannelPageDocument,
    baseOptions
  )
}

export type GetChannelPageQueryHookResult = ReturnType<typeof useGetChannelPageQuery>
export type GetChannelPageQueryResult = ApolloReactCommon.QueryResult<
  GetChannelPageQuery,
  GetChannelPageQueryVariables
>
export const UpdateChannelPlaylistsOrderDocument = gql`
  mutation UpdateChannelPlaylistsOrder(
    $id: String!
    $order: [String!]!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    updateChannelPlaylistsOrder(id: $id, order: $order) {
      _id
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelViewDataFragmentDoc}
  ${ChannelEditDataFragmentDoc}
`
export type UpdateChannelPlaylistsOrderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateChannelPlaylistsOrderMutation,
  UpdateChannelPlaylistsOrderMutationVariables
>

export function useUpdateChannelPlaylistsOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateChannelPlaylistsOrderMutation,
    UpdateChannelPlaylistsOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateChannelPlaylistsOrderMutation,
    UpdateChannelPlaylistsOrderMutationVariables
  >(UpdateChannelPlaylistsOrderDocument, baseOptions)
}
export type UpdateChannelPlaylistsOrderMutationHookResult = ReturnType<
  typeof useUpdateChannelPlaylistsOrderMutation
>
export type UpdateChannelPlaylistsOrderMutationResult = ApolloReactCommon.MutationResult<
  UpdateChannelPlaylistsOrderMutation
>
export type UpdateChannelPlaylistsOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateChannelPlaylistsOrderMutation,
  UpdateChannelPlaylistsOrderMutationVariables
>
export const UpdateChannelDocument = gql`
  mutation UpdateChannel(
    $id: String!
    $channel: UpdateChannelInput!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    updateChannel(id: $id, channel: $channel) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelViewDataFragmentDoc}
  ${ChannelEditDataFragmentDoc}
`
export type UpdateChannelMutationFn = ApolloReactCommon.MutationFunction<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>

export function useUpdateChannelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(
    UpdateChannelDocument,
    baseOptions
  )
}
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>
export type UpdateChannelMutationResult = ApolloReactCommon.MutationResult<UpdateChannelMutation>
export type UpdateChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>
export const RemoveChannelDocument = gql`
  mutation RemoveChannel($id: String!) {
    removeChannel(id: $id)
  }
`
export type RemoveChannelMutationFn = ApolloReactCommon.MutationFunction<
  RemoveChannelMutation,
  RemoveChannelMutationVariables
>

export function useRemoveChannelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveChannelMutation,
    RemoveChannelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RemoveChannelMutation, RemoveChannelMutationVariables>(
    RemoveChannelDocument,
    baseOptions
  )
}
export type RemoveChannelMutationHookResult = ReturnType<typeof useRemoveChannelMutation>
export type RemoveChannelMutationResult = ApolloReactCommon.MutationResult<RemoveChannelMutation>
export type RemoveChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveChannelMutation,
  RemoveChannelMutationVariables
>
export const RemoveChannelFeaturedVideoDocument = gql`
  mutation RemoveChannelFeaturedVideo(
    $id: String!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    removeChannelFeaturedVideo(id: $id) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelViewDataFragmentDoc}
  ${ChannelEditDataFragmentDoc}
`
export type RemoveChannelFeaturedVideoMutationFn = ApolloReactCommon.MutationFunction<
  RemoveChannelFeaturedVideoMutation,
  RemoveChannelFeaturedVideoMutationVariables
>

export function useRemoveChannelFeaturedVideoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveChannelFeaturedVideoMutation,
    RemoveChannelFeaturedVideoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveChannelFeaturedVideoMutation,
    RemoveChannelFeaturedVideoMutationVariables
  >(RemoveChannelFeaturedVideoDocument, baseOptions)
}
export type RemoveChannelFeaturedVideoMutationHookResult = ReturnType<
  typeof useRemoveChannelFeaturedVideoMutation
>
export type RemoveChannelFeaturedVideoMutationResult = ApolloReactCommon.MutationResult<
  RemoveChannelFeaturedVideoMutation
>
export type RemoveChannelFeaturedVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveChannelFeaturedVideoMutation,
  RemoveChannelFeaturedVideoMutationVariables
>
export const RemoveChannelLiveStreamVideoDocument = gql`
  mutation RemoveChannelLiveStreamVideo(
    $id: String!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    removeChannelLiveStreamVideo(id: $id) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelViewDataFragmentDoc}
  ${ChannelEditDataFragmentDoc}
`
export type RemoveChannelLiveStreamVideoMutationFn = ApolloReactCommon.MutationFunction<
  RemoveChannelLiveStreamVideoMutation,
  RemoveChannelLiveStreamVideoMutationVariables
>

export function useRemoveChannelLiveStreamVideoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveChannelLiveStreamVideoMutation,
    RemoveChannelLiveStreamVideoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveChannelLiveStreamVideoMutation,
    RemoveChannelLiveStreamVideoMutationVariables
  >(RemoveChannelLiveStreamVideoDocument, baseOptions)
}
export type RemoveChannelLiveStreamVideoMutationHookResult = ReturnType<
  typeof useRemoveChannelLiveStreamVideoMutation
>
export type RemoveChannelLiveStreamVideoMutationResult = ApolloReactCommon.MutationResult<
  RemoveChannelLiveStreamVideoMutation
>
export type RemoveChannelLiveStreamVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveChannelLiveStreamVideoMutation,
  RemoveChannelLiveStreamVideoMutationVariables
>
export const GetChannelsPageDocument = gql`
  query GetChannelsPage($channelVideoLimit: Float, $channelVideoOffset: Float) {
    getAdminChannels {
      ...ChannelListItemData
      ...ChannelTableData
    }
  }
  ${ChannelListItemDataFragmentDoc}
  ${ChannelTableDataFragmentDoc}
`

export function useGetChannelsPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetChannelsPageQuery,
    GetChannelsPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetChannelsPageQuery, GetChannelsPageQueryVariables>(
    GetChannelsPageDocument,
    baseOptions
  )
}
export function useGetChannelsPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChannelsPageQuery,
    GetChannelsPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetChannelsPageQuery, GetChannelsPageQueryVariables>(
    GetChannelsPageDocument,
    baseOptions
  )
}

export type GetChannelsPageQueryHookResult = ReturnType<typeof useGetChannelsPageQuery>
export type GetChannelsPageQueryResult = ApolloReactCommon.QueryResult<
  GetChannelsPageQuery,
  GetChannelsPageQueryVariables
>
export const CreateChannelDocument = gql`
  mutation CreateChannel(
    $channel: ChannelInput!
    $channelVideoLimit: Float
    $channelVideoOffset: Float
  ) {
    createChannel(channel: $channel) {
      ...ChannelListItemData
      ...ChannelTableData
    }
  }
  ${ChannelListItemDataFragmentDoc}
  ${ChannelTableDataFragmentDoc}
`
export type CreateChannelMutationFn = ApolloReactCommon.MutationFunction<
  CreateChannelMutation,
  CreateChannelMutationVariables
>

export function useCreateChannelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateChannelMutation,
    CreateChannelMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(
    CreateChannelDocument,
    baseOptions
  )
}
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>
export type CreateChannelMutationResult = ApolloReactCommon.MutationResult<CreateChannelMutation>
export type CreateChannelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateChannelMutation,
  CreateChannelMutationVariables
>
export const GetAllCommentsDocument = gql`
  query GetAllComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getAllComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`

export function useGetAllCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCommentsQuery, GetAllCommentsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetAllCommentsQuery, GetAllCommentsQueryVariables>(
    GetAllCommentsDocument,
    baseOptions
  )
}
export function useGetAllCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllCommentsQuery,
    GetAllCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllCommentsQuery, GetAllCommentsQueryVariables>(
    GetAllCommentsDocument,
    baseOptions
  )
}

export type GetAllCommentsQueryHookResult = ReturnType<typeof useGetAllCommentsQuery>
export type GetAllCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetAllCommentsQuery,
  GetAllCommentsQueryVariables
>
export const GetReportedCommentsDocument = gql`
  query GetReportedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getReportedComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
      updatedAt
    }
  }
  ${CommentTabDataFragmentDoc}
`

export function useGetReportedCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReportedCommentsQuery,
    GetReportedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetReportedCommentsQuery, GetReportedCommentsQueryVariables>(
    GetReportedCommentsDocument,
    baseOptions
  )
}
export function useGetReportedCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReportedCommentsQuery,
    GetReportedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetReportedCommentsQuery, GetReportedCommentsQueryVariables>(
    GetReportedCommentsDocument,
    baseOptions
  )
}

export type GetReportedCommentsQueryHookResult = ReturnType<typeof useGetReportedCommentsQuery>
export type GetReportedCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetReportedCommentsQuery,
  GetReportedCommentsQueryVariables
>
export const GetApprovedCommentsDocument = gql`
  query GetApprovedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getApprovedComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`

export function useGetApprovedCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetApprovedCommentsQuery,
    GetApprovedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetApprovedCommentsQuery, GetApprovedCommentsQueryVariables>(
    GetApprovedCommentsDocument,
    baseOptions
  )
}
export function useGetApprovedCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetApprovedCommentsQuery,
    GetApprovedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetApprovedCommentsQuery, GetApprovedCommentsQueryVariables>(
    GetApprovedCommentsDocument,
    baseOptions
  )
}

export type GetApprovedCommentsQueryHookResult = ReturnType<typeof useGetApprovedCommentsQuery>
export type GetApprovedCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetApprovedCommentsQuery,
  GetApprovedCommentsQueryVariables
>
export const GetRejectedCommentsDocument = gql`
  query GetRejectedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getRejectedComments(limit: $limit, offset: $offset, query: $query) {
      bannedFromComment
      duplicate
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`

export function useGetRejectedCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRejectedCommentsQuery,
    GetRejectedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetRejectedCommentsQuery, GetRejectedCommentsQueryVariables>(
    GetRejectedCommentsDocument,
    baseOptions
  )
}
export function useGetRejectedCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRejectedCommentsQuery,
    GetRejectedCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetRejectedCommentsQuery, GetRejectedCommentsQueryVariables>(
    GetRejectedCommentsDocument,
    baseOptions
  )
}

export type GetRejectedCommentsQueryHookResult = ReturnType<typeof useGetRejectedCommentsQuery>
export type GetRejectedCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetRejectedCommentsQuery,
  GetRejectedCommentsQueryVariables
>
export const GetUnfilteredCommentsDocument = gql`
  query GetUnfilteredComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getUnfilteredComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`

export function useGetUnfilteredCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUnfilteredCommentsQuery,
    GetUnfilteredCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetUnfilteredCommentsQuery, GetUnfilteredCommentsQueryVariables>(
    GetUnfilteredCommentsDocument,
    baseOptions
  )
}
export function useGetUnfilteredCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUnfilteredCommentsQuery,
    GetUnfilteredCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUnfilteredCommentsQuery,
    GetUnfilteredCommentsQueryVariables
  >(GetUnfilteredCommentsDocument, baseOptions)
}

export type GetUnfilteredCommentsQueryHookResult = ReturnType<typeof useGetUnfilteredCommentsQuery>
export type GetUnfilteredCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetUnfilteredCommentsQuery,
  GetUnfilteredCommentsQueryVariables
>
export const GetAllCommentsAmountSinceDocument = gql`
  query GetAllCommentsAmountSince($since: DateTime!) {
    getAllCommentsAmountSince(since: $since)
  }
`

export function useGetAllCommentsAmountSinceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllCommentsAmountSinceQuery,
    GetAllCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllCommentsAmountSinceQuery,
    GetAllCommentsAmountSinceQueryVariables
  >(GetAllCommentsAmountSinceDocument, baseOptions)
}
export function useGetAllCommentsAmountSinceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllCommentsAmountSinceQuery,
    GetAllCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllCommentsAmountSinceQuery,
    GetAllCommentsAmountSinceQueryVariables
  >(GetAllCommentsAmountSinceDocument, baseOptions)
}

export type GetAllCommentsAmountSinceQueryHookResult = ReturnType<
  typeof useGetAllCommentsAmountSinceQuery
>
export type GetAllCommentsAmountSinceQueryResult = ApolloReactCommon.QueryResult<
  GetAllCommentsAmountSinceQuery,
  GetAllCommentsAmountSinceQueryVariables
>
export const GetReportedCommentsAmountSinceDocument = gql`
  query GetReportedCommentsAmountSince($since: DateTime!) {
    getReportedCommentsAmountSince(since: $since)
  }
`

export function useGetReportedCommentsAmountSinceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetReportedCommentsAmountSinceQuery,
    GetReportedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetReportedCommentsAmountSinceQuery,
    GetReportedCommentsAmountSinceQueryVariables
  >(GetReportedCommentsAmountSinceDocument, baseOptions)
}
export function useGetReportedCommentsAmountSinceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetReportedCommentsAmountSinceQuery,
    GetReportedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetReportedCommentsAmountSinceQuery,
    GetReportedCommentsAmountSinceQueryVariables
  >(GetReportedCommentsAmountSinceDocument, baseOptions)
}

export type GetReportedCommentsAmountSinceQueryHookResult = ReturnType<
  typeof useGetReportedCommentsAmountSinceQuery
>
export type GetReportedCommentsAmountSinceQueryResult = ApolloReactCommon.QueryResult<
  GetReportedCommentsAmountSinceQuery,
  GetReportedCommentsAmountSinceQueryVariables
>
export const GetApprovedCommentsAmountSinceDocument = gql`
  query GetApprovedCommentsAmountSince($since: DateTime!) {
    getApprovedCommentsAmountSince(since: $since)
  }
`

export function useGetApprovedCommentsAmountSinceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetApprovedCommentsAmountSinceQuery,
    GetApprovedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetApprovedCommentsAmountSinceQuery,
    GetApprovedCommentsAmountSinceQueryVariables
  >(GetApprovedCommentsAmountSinceDocument, baseOptions)
}
export function useGetApprovedCommentsAmountSinceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetApprovedCommentsAmountSinceQuery,
    GetApprovedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetApprovedCommentsAmountSinceQuery,
    GetApprovedCommentsAmountSinceQueryVariables
  >(GetApprovedCommentsAmountSinceDocument, baseOptions)
}

export type GetApprovedCommentsAmountSinceQueryHookResult = ReturnType<
  typeof useGetApprovedCommentsAmountSinceQuery
>
export type GetApprovedCommentsAmountSinceQueryResult = ApolloReactCommon.QueryResult<
  GetApprovedCommentsAmountSinceQuery,
  GetApprovedCommentsAmountSinceQueryVariables
>
export const GetRejectedCommentsAmountSinceDocument = gql`
  query GetRejectedCommentsAmountSince($since: DateTime!) {
    getRejectedCommentsAmountSince(since: $since)
  }
`

export function useGetRejectedCommentsAmountSinceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRejectedCommentsAmountSinceQuery,
    GetRejectedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetRejectedCommentsAmountSinceQuery,
    GetRejectedCommentsAmountSinceQueryVariables
  >(GetRejectedCommentsAmountSinceDocument, baseOptions)
}
export function useGetRejectedCommentsAmountSinceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRejectedCommentsAmountSinceQuery,
    GetRejectedCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetRejectedCommentsAmountSinceQuery,
    GetRejectedCommentsAmountSinceQueryVariables
  >(GetRejectedCommentsAmountSinceDocument, baseOptions)
}

export type GetRejectedCommentsAmountSinceQueryHookResult = ReturnType<
  typeof useGetRejectedCommentsAmountSinceQuery
>
export type GetRejectedCommentsAmountSinceQueryResult = ApolloReactCommon.QueryResult<
  GetRejectedCommentsAmountSinceQuery,
  GetRejectedCommentsAmountSinceQueryVariables
>
export const GetUnfilteredCommentsAmountSinceDocument = gql`
  query GetUnfilteredCommentsAmountSince($since: DateTime!) {
    getUnfilteredCommentsAmountSince(since: $since)
  }
`

export function useGetUnfilteredCommentsAmountSinceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUnfilteredCommentsAmountSinceQuery,
    GetUnfilteredCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUnfilteredCommentsAmountSinceQuery,
    GetUnfilteredCommentsAmountSinceQueryVariables
  >(GetUnfilteredCommentsAmountSinceDocument, baseOptions)
}
export function useGetUnfilteredCommentsAmountSinceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUnfilteredCommentsAmountSinceQuery,
    GetUnfilteredCommentsAmountSinceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUnfilteredCommentsAmountSinceQuery,
    GetUnfilteredCommentsAmountSinceQueryVariables
  >(GetUnfilteredCommentsAmountSinceDocument, baseOptions)
}

export type GetUnfilteredCommentsAmountSinceQueryHookResult = ReturnType<
  typeof useGetUnfilteredCommentsAmountSinceQuery
>
export type GetUnfilteredCommentsAmountSinceQueryResult = ApolloReactCommon.QueryResult<
  GetUnfilteredCommentsAmountSinceQuery,
  GetUnfilteredCommentsAmountSinceQueryVariables
>
export const ApproveCommentDocument = gql`
  mutation ApproveComment($id: String!) {
    approveComment(id: $id) {
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`
export type ApproveCommentMutationFn = ApolloReactCommon.MutationFunction<
  ApproveCommentMutation,
  ApproveCommentMutationVariables
>

export function useApproveCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApproveCommentMutation,
    ApproveCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ApproveCommentMutation, ApproveCommentMutationVariables>(
    ApproveCommentDocument,
    baseOptions
  )
}
export type ApproveCommentMutationHookResult = ReturnType<typeof useApproveCommentMutation>
export type ApproveCommentMutationResult = ApolloReactCommon.MutationResult<ApproveCommentMutation>
export type ApproveCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApproveCommentMutation,
  ApproveCommentMutationVariables
>
export const RejectCommentDocument = gql`
  mutation RejectComment($id: String!) {
    rejectComment(id: $id) {
      ...CommentTabData
    }
  }
  ${CommentTabDataFragmentDoc}
`
export type RejectCommentMutationFn = ApolloReactCommon.MutationFunction<
  RejectCommentMutation,
  RejectCommentMutationVariables
>

export function useRejectCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectCommentMutation,
    RejectCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RejectCommentMutation, RejectCommentMutationVariables>(
    RejectCommentDocument,
    baseOptions
  )
}
export type RejectCommentMutationHookResult = ReturnType<typeof useRejectCommentMutation>
export type RejectCommentMutationResult = ApolloReactCommon.MutationResult<RejectCommentMutation>
export type RejectCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectCommentMutation,
  RejectCommentMutationVariables
>
export const ForgotPasswordAdminDocument = gql`
  mutation ForgotPasswordAdmin($email: String!) {
    forgotPasswordAdmin(email: $email)
  }
`
export type ForgotPasswordAdminMutationFn = ApolloReactCommon.MutationFunction<
  ForgotPasswordAdminMutation,
  ForgotPasswordAdminMutationVariables
>

export function useForgotPasswordAdminMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForgotPasswordAdminMutation,
    ForgotPasswordAdminMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ForgotPasswordAdminMutation,
    ForgotPasswordAdminMutationVariables
  >(ForgotPasswordAdminDocument, baseOptions)
}
export type ForgotPasswordAdminMutationHookResult = ReturnType<
  typeof useForgotPasswordAdminMutation
>
export type ForgotPasswordAdminMutationResult = ApolloReactCommon.MutationResult<
  ForgotPasswordAdminMutation
>
export type ForgotPasswordAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ForgotPasswordAdminMutation,
  ForgotPasswordAdminMutationVariables
>
export const ResetPasswordAdminTokenDocument = gql`
  mutation ResetPasswordAdminToken(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmed: String!
  ) {
    resetPasswordAdminToken(
      token: $token
      newPassword: $newPassword
      newPasswordConfirmed: $newPasswordConfirmed
    )
  }
`
export type ResetPasswordAdminTokenMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordAdminTokenMutation,
  ResetPasswordAdminTokenMutationVariables
>

export function useResetPasswordAdminTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordAdminTokenMutation,
    ResetPasswordAdminTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordAdminTokenMutation,
    ResetPasswordAdminTokenMutationVariables
  >(ResetPasswordAdminTokenDocument, baseOptions)
}
export type ResetPasswordAdminTokenMutationHookResult = ReturnType<
  typeof useResetPasswordAdminTokenMutation
>
export type ResetPasswordAdminTokenMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordAdminTokenMutation
>
export type ResetPasswordAdminTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordAdminTokenMutation,
  ResetPasswordAdminTokenMutationVariables
>
export const GetActiveHomeConfigDocument = gql`
  query GetActiveHomeConfig {
    adminGetActiveConfig {
      _id
      title
      featuredVideos {
        ...VideoTableData
        ...VideoCardData
      }
      navigationChannels {
        ...PageItemsListData
      }
    }
  }
  ${VideoTableDataFragmentDoc}
  ${VideoCardDataFragmentDoc}
  ${PageItemsListDataFragmentDoc}
`

export function useGetActiveHomeConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetActiveHomeConfigQuery,
    GetActiveHomeConfigQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetActiveHomeConfigQuery, GetActiveHomeConfigQueryVariables>(
    GetActiveHomeConfigDocument,
    baseOptions
  )
}
export function useGetActiveHomeConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetActiveHomeConfigQuery,
    GetActiveHomeConfigQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetActiveHomeConfigQuery, GetActiveHomeConfigQueryVariables>(
    GetActiveHomeConfigDocument,
    baseOptions
  )
}

export type GetActiveHomeConfigQueryHookResult = ReturnType<typeof useGetActiveHomeConfigQuery>
export type GetActiveHomeConfigQueryResult = ApolloReactCommon.QueryResult<
  GetActiveHomeConfigQuery,
  GetActiveHomeConfigQueryVariables
>
export const UpdateConfigDocument = gql`
  mutation UpdateConfig($id: String!, $config: UpdateConfigInput!) {
    updateConfig(id: $id, config: $config) {
      _id
    }
  }
`
export type UpdateConfigMutationFn = ApolloReactCommon.MutationFunction<
  UpdateConfigMutation,
  UpdateConfigMutationVariables
>

export function useUpdateConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateConfigMutation,
    UpdateConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateConfigMutation, UpdateConfigMutationVariables>(
    UpdateConfigDocument,
    baseOptions
  )
}
export type UpdateConfigMutationHookResult = ReturnType<typeof useUpdateConfigMutation>
export type UpdateConfigMutationResult = ApolloReactCommon.MutationResult<UpdateConfigMutation>
export type UpdateConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateConfigMutation,
  UpdateConfigMutationVariables
>
export const GetPlaylistPageDocument = gql`
  query GetPlaylistPage($id: String!, $playlistVideoLimit: Float, $playlistVideoOffset: Float) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    getAdminPlaylist(id: $id) {
      ...PlaylistListItemData
    }
  }
  ${PlaylistListItemDataFragmentDoc}
`

export function useGetPlaylistPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistPageQuery,
    GetPlaylistPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPlaylistPageQuery, GetPlaylistPageQueryVariables>(
    GetPlaylistPageDocument,
    baseOptions
  )
}
export function useGetPlaylistPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistPageQuery,
    GetPlaylistPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPlaylistPageQuery, GetPlaylistPageQueryVariables>(
    GetPlaylistPageDocument,
    baseOptions
  )
}

export type GetPlaylistPageQueryHookResult = ReturnType<typeof useGetPlaylistPageQuery>
export type GetPlaylistPageQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistPageQuery,
  GetPlaylistPageQueryVariables
>
export const GetPlaylistVideoIdsDocument = gql`
  query GetPlaylistVideoIds($id: String!) {
    getAdminPlaylist(id: $id) {
      _id
      videoIds
    }
  }
`

export function useGetPlaylistVideoIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistVideoIdsQuery,
    GetPlaylistVideoIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPlaylistVideoIdsQuery, GetPlaylistVideoIdsQueryVariables>(
    GetPlaylistVideoIdsDocument,
    baseOptions
  )
}
export function useGetPlaylistVideoIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistVideoIdsQuery,
    GetPlaylistVideoIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPlaylistVideoIdsQuery, GetPlaylistVideoIdsQueryVariables>(
    GetPlaylistVideoIdsDocument,
    baseOptions
  )
}

export type GetPlaylistVideoIdsQueryHookResult = ReturnType<typeof useGetPlaylistVideoIdsQuery>
export type GetPlaylistVideoIdsQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistVideoIdsQuery,
  GetPlaylistVideoIdsQueryVariables
>
export const UpdatePlaylistDocument = gql`
  mutation UpdatePlaylist(
    $id: String!
    $playlist: UpdatePlaylistInput!
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    updatePlaylist(id: $id, playlist: $playlist) {
      ...PlaylistListItemData
    }
  }
  ${PlaylistListItemDataFragmentDoc}
`
export type UpdatePlaylistMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables
>

export function useUpdatePlaylistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdatePlaylistMutation, UpdatePlaylistMutationVariables>(
    UpdatePlaylistDocument,
    baseOptions
  )
}
export type UpdatePlaylistMutationHookResult = ReturnType<typeof useUpdatePlaylistMutation>
export type UpdatePlaylistMutationResult = ApolloReactCommon.MutationResult<UpdatePlaylistMutation>
export type UpdatePlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables
>
export const RemovePlaylistDocument = gql`
  mutation RemovePlaylist($id: String!) {
    removePlaylist(id: $id)
  }
`
export type RemovePlaylistMutationFn = ApolloReactCommon.MutationFunction<
  RemovePlaylistMutation,
  RemovePlaylistMutationVariables
>

export function useRemovePlaylistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePlaylistMutation,
    RemovePlaylistMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RemovePlaylistMutation, RemovePlaylistMutationVariables>(
    RemovePlaylistDocument,
    baseOptions
  )
}
export type RemovePlaylistMutationHookResult = ReturnType<typeof useRemovePlaylistMutation>
export type RemovePlaylistMutationResult = ApolloReactCommon.MutationResult<RemovePlaylistMutation>
export type RemovePlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePlaylistMutation,
  RemovePlaylistMutationVariables
>
export const GetPlaylistsPageDocument = gql`
  query GetPlaylistsPage(
    $limit: Float
    $offset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    getAdminPlaylists(limit: $limit, offset: $offset) {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistListItemDataFragmentDoc}
  ${PlaylistTableDataFragmentDoc}
`

export function useGetPlaylistsPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlaylistsPageQuery,
    GetPlaylistsPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetPlaylistsPageQuery, GetPlaylistsPageQueryVariables>(
    GetPlaylistsPageDocument,
    baseOptions
  )
}
export function useGetPlaylistsPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlaylistsPageQuery,
    GetPlaylistsPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetPlaylistsPageQuery, GetPlaylistsPageQueryVariables>(
    GetPlaylistsPageDocument,
    baseOptions
  )
}

export type GetPlaylistsPageQueryHookResult = ReturnType<typeof useGetPlaylistsPageQuery>
export type GetPlaylistsPageQueryResult = ApolloReactCommon.QueryResult<
  GetPlaylistsPageQuery,
  GetPlaylistsPageQueryVariables
>
export const CreatePlaylistDocument = gql`
  mutation CreatePlaylist(
    $playlist: PlaylistInput!
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "limit")
    createPlaylist(playlist: $playlist) {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistListItemDataFragmentDoc}
  ${PlaylistTableDataFragmentDoc}
`
export type CreatePlaylistMutationFn = ApolloReactCommon.MutationFunction<
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables
>

export function useCreatePlaylistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreatePlaylistMutation, CreatePlaylistMutationVariables>(
    CreatePlaylistDocument,
    baseOptions
  )
}
export type CreatePlaylistMutationHookResult = ReturnType<typeof useCreatePlaylistMutation>
export type CreatePlaylistMutationResult = ApolloReactCommon.MutationResult<CreatePlaylistMutation>
export type CreatePlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables
>
export const GetSalesDocument = gql`
  query GetSales {
    getSales {
      ...SalesTextListData
    }
  }
  ${SalesTextListDataFragmentDoc}
`

export function useGetSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesQuery, GetSalesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetSalesQuery, GetSalesQueryVariables>(
    GetSalesDocument,
    baseOptions
  )
}
export function useGetSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesQuery, GetSalesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetSalesQuery, GetSalesQueryVariables>(
    GetSalesDocument,
    baseOptions
  )
}

export type GetSalesQueryHookResult = ReturnType<typeof useGetSalesQuery>
export type GetSalesQueryResult = ApolloReactCommon.QueryResult<
  GetSalesQuery,
  GetSalesQueryVariables
>
export const CreateSaleDocument = gql`
  mutation CreateSale(
    $text: String!
    $description: String!
    $url: String!
    $videoUploadId: String!
    $textSale: String!
  ) {
    createSale(
      text: $text
      description: $description
      url: $url
      videoUploadId: $videoUploadId
      textSale: $textSale
    ) {
      ...SalesTextListData
    }
  }
  ${SalesTextListDataFragmentDoc}
`
export type CreateSaleMutationFn = ApolloReactCommon.MutationFunction<
  CreateSaleMutation,
  CreateSaleMutationVariables
>

export function useCreateSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSaleMutation,
    CreateSaleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(
    CreateSaleDocument,
    baseOptions
  )
}
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>
export type CreateSaleMutationResult = ApolloReactCommon.MutationResult<CreateSaleMutation>
export type CreateSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSaleMutation,
  CreateSaleMutationVariables
>
export const UpdateSaleDocument = gql`
  mutation UpdateSale(
    $id: String!
    $text: String!
    $description: String!
    $url: String!
    $textSale: String!
  ) {
    updateSale(id: $id, text: $text, description: $description, url: $url, textSale: $textSale) {
      ...SalesTextListData
    }
  }
  ${SalesTextListDataFragmentDoc}
`
export type UpdateSaleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSaleMutation,
  UpdateSaleMutationVariables
>

export function useUpdateSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSaleMutation,
    UpdateSaleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(
    UpdateSaleDocument,
    baseOptions
  )
}
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>
export type UpdateSaleMutationResult = ApolloReactCommon.MutationResult<UpdateSaleMutation>
export type UpdateSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSaleMutation,
  UpdateSaleMutationVariables
>
export const RemoveSaleDocument = gql`
  mutation RemoveSale($id: String!) {
    removeSale(id: $id)
  }
`
export type RemoveSaleMutationFn = ApolloReactCommon.MutationFunction<
  RemoveSaleMutation,
  RemoveSaleMutationVariables
>

export function useRemoveSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveSaleMutation,
    RemoveSaleMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RemoveSaleMutation, RemoveSaleMutationVariables>(
    RemoveSaleDocument,
    baseOptions
  )
}
export type RemoveSaleMutationHookResult = ReturnType<typeof useRemoveSaleMutation>
export type RemoveSaleMutationResult = ApolloReactCommon.MutationResult<RemoveSaleMutation>
export type RemoveSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveSaleMutation,
  RemoveSaleMutationVariables
>
export const ToggleHiddenDocument = gql`
  mutation ToggleHidden($id: String!, $isChecked: Boolean!) {
    toggleHidden(id: $id, isChecked: $isChecked)
  }
`
export type ToggleHiddenMutationFn = ApolloReactCommon.MutationFunction<
  ToggleHiddenMutation,
  ToggleHiddenMutationVariables
>

export function useToggleHiddenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleHiddenMutation,
    ToggleHiddenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ToggleHiddenMutation, ToggleHiddenMutationVariables>(
    ToggleHiddenDocument,
    baseOptions
  )
}
export type ToggleHiddenMutationHookResult = ReturnType<typeof useToggleHiddenMutation>
export type ToggleHiddenMutationResult = ApolloReactCommon.MutationResult<ToggleHiddenMutation>
export type ToggleHiddenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleHiddenMutation,
  ToggleHiddenMutationVariables
>
export const ResetSaleClicksDocument = gql`
  mutation ResetSaleClicks($id: String!) {
    resetSaleClicks(id: $id) {
      ...SalesTextListData
    }
  }
  ${SalesTextListDataFragmentDoc}
`
export type ResetSaleClicksMutationFn = ApolloReactCommon.MutationFunction<
  ResetSaleClicksMutation,
  ResetSaleClicksMutationVariables
>

export function useResetSaleClicksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetSaleClicksMutation,
    ResetSaleClicksMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ResetSaleClicksMutation, ResetSaleClicksMutationVariables>(
    ResetSaleClicksDocument,
    baseOptions
  )
}
export type ResetSaleClicksMutationHookResult = ReturnType<typeof useResetSaleClicksMutation>
export type ResetSaleClicksMutationResult = ApolloReactCommon.MutationResult<
  ResetSaleClicksMutation
>
export type ResetSaleClicksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetSaleClicksMutation,
  ResetSaleClicksMutationVariables
>
export const ResetAllSaleClicksDocument = gql`
  mutation ResetAllSaleClicks {
    resetAllSaleClicks {
      ...SalesTextListData
    }
  }
  ${SalesTextListDataFragmentDoc}
`
export type ResetAllSaleClicksMutationFn = ApolloReactCommon.MutationFunction<
  ResetAllSaleClicksMutation,
  ResetAllSaleClicksMutationVariables
>

export function useResetAllSaleClicksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetAllSaleClicksMutation,
    ResetAllSaleClicksMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetAllSaleClicksMutation,
    ResetAllSaleClicksMutationVariables
  >(ResetAllSaleClicksDocument, baseOptions)
}
export type ResetAllSaleClicksMutationHookResult = ReturnType<typeof useResetAllSaleClicksMutation>
export type ResetAllSaleClicksMutationResult = ApolloReactCommon.MutationResult<
  ResetAllSaleClicksMutation
>
export type ResetAllSaleClicksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetAllSaleClicksMutation,
  ResetAllSaleClicksMutationVariables
>
export const GetScheduledVideosDocument = gql`
  query GetScheduledVideos {
    getScheduledVideos {
      ...ScheduledVideoTableData
    }
  }
  ${ScheduledVideoTableDataFragmentDoc}
`

export function useGetScheduledVideosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetScheduledVideosQuery,
    GetScheduledVideosQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetScheduledVideosQuery, GetScheduledVideosQueryVariables>(
    GetScheduledVideosDocument,
    baseOptions
  )
}
export function useGetScheduledVideosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetScheduledVideosQuery,
    GetScheduledVideosQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetScheduledVideosQuery, GetScheduledVideosQueryVariables>(
    GetScheduledVideosDocument,
    baseOptions
  )
}

export type GetScheduledVideosQueryHookResult = ReturnType<typeof useGetScheduledVideosQuery>
export type GetScheduledVideosQueryResult = ApolloReactCommon.QueryResult<
  GetScheduledVideosQuery,
  GetScheduledVideosQueryVariables
>
export const GetUserDocument = gql`
  query GetUser($username: String) {
    user(username: $username) {
      _id
      commentBan
      createdAt
    }
  }
`

export function useGetUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  )
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  )
}

export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>
export const GetUserCommentsDocument = gql`
  query GetUserComments($id: String!, $limit: Float, $offset: Float) {
    commentPageLimit @client @export(as: "limit")
    getUserComments(id: $id, limit: $limit, offset: $offset) {
      ...CommentListData
    }
  }
  ${CommentListDataFragmentDoc}
`

export function useGetUserCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserCommentsQuery,
    GetUserCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetUserCommentsQuery, GetUserCommentsQueryVariables>(
    GetUserCommentsDocument,
    baseOptions
  )
}
export function useGetUserCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserCommentsQuery,
    GetUserCommentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetUserCommentsQuery, GetUserCommentsQueryVariables>(
    GetUserCommentsDocument,
    baseOptions
  )
}

export type GetUserCommentsQueryHookResult = ReturnType<typeof useGetUserCommentsQuery>
export type GetUserCommentsQueryResult = ApolloReactCommon.QueryResult<
  GetUserCommentsQuery,
  GetUserCommentsQueryVariables
>
export const ToggleUserCommentBanDocument = gql`
  mutation ToggleUserCommentBan($id: String!) {
    toggleUserCommentBan(id: $id) {
      _id
      commentBan
    }
  }
`
export type ToggleUserCommentBanMutationFn = ApolloReactCommon.MutationFunction<
  ToggleUserCommentBanMutation,
  ToggleUserCommentBanMutationVariables
>

export function useToggleUserCommentBanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ToggleUserCommentBanMutation,
    ToggleUserCommentBanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ToggleUserCommentBanMutation,
    ToggleUserCommentBanMutationVariables
  >(ToggleUserCommentBanDocument, baseOptions)
}
export type ToggleUserCommentBanMutationHookResult = ReturnType<
  typeof useToggleUserCommentBanMutation
>
export type ToggleUserCommentBanMutationResult = ApolloReactCommon.MutationResult<
  ToggleUserCommentBanMutation
>
export type ToggleUserCommentBanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ToggleUserCommentBanMutation,
  ToggleUserCommentBanMutationVariables
>
export const GetVideoPageDocument = gql`
  query GetVideoPage($id: String!) {
    getVideo(id: $id) {
      _id
      audio
      playlists {
        ...PlaylistCardData
      }
      ...VideoViewData
    }
  }
  ${PlaylistCardDataFragmentDoc}
  ${VideoViewDataFragmentDoc}
`

export function useGetVideoPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetVideoPageQuery, GetVideoPageQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetVideoPageQuery, GetVideoPageQueryVariables>(
    GetVideoPageDocument,
    baseOptions
  )
}
export function useGetVideoPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVideoPageQuery, GetVideoPageQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetVideoPageQuery, GetVideoPageQueryVariables>(
    GetVideoPageDocument,
    baseOptions
  )
}

export type GetVideoPageQueryHookResult = ReturnType<typeof useGetVideoPageQuery>
export type GetVideoPageQueryResult = ApolloReactCommon.QueryResult<
  GetVideoPageQuery,
  GetVideoPageQueryVariables
>
export const UpdateVideoDocument = gql`
  mutation UpdateVideo($id: String!, $video: UpdateVideoInput!, $create: Boolean) {
    updateVideo(id: $id, video: $video, create: $create) {
      _id
      ...VideoViewData
    }
  }
  ${VideoViewDataFragmentDoc}
`
export type UpdateVideoMutationFn = ApolloReactCommon.MutationFunction<
  UpdateVideoMutation,
  UpdateVideoMutationVariables
>

export function useUpdateVideoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateVideoMutation,
    UpdateVideoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(
    UpdateVideoDocument,
    baseOptions
  )
}
export type UpdateVideoMutationHookResult = ReturnType<typeof useUpdateVideoMutation>
export type UpdateVideoMutationResult = ApolloReactCommon.MutationResult<UpdateVideoMutation>
export type UpdateVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateVideoMutation,
  UpdateVideoMutationVariables
>
export const RemoveVideoDocument = gql`
  mutation RemoveVideo($id: String!) {
    removeVideo(id: $id)
  }
`
export type RemoveVideoMutationFn = ApolloReactCommon.MutationFunction<
  RemoveVideoMutation,
  RemoveVideoMutationVariables
>

export function useRemoveVideoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveVideoMutation,
    RemoveVideoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<RemoveVideoMutation, RemoveVideoMutationVariables>(
    RemoveVideoDocument,
    baseOptions
  )
}
export type RemoveVideoMutationHookResult = ReturnType<typeof useRemoveVideoMutation>
export type RemoveVideoMutationResult = ApolloReactCommon.MutationResult<RemoveVideoMutation>
export type RemoveVideoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveVideoMutation,
  RemoveVideoMutationVariables
>
export const AddVideosToPlaylistDocument = gql`
  mutation AddVideosToPlaylist($id: String!, $videoIds: [String!]!) {
    addVideosToPlaylist(id: $id, videoIds: $videoIds) {
      ...PlaylistCardData
    }
  }
  ${PlaylistCardDataFragmentDoc}
`
export type AddVideosToPlaylistMutationFn = ApolloReactCommon.MutationFunction<
  AddVideosToPlaylistMutation,
  AddVideosToPlaylistMutationVariables
>

export function useAddVideosToPlaylistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddVideosToPlaylistMutation,
    AddVideosToPlaylistMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddVideosToPlaylistMutation,
    AddVideosToPlaylistMutationVariables
  >(AddVideosToPlaylistDocument, baseOptions)
}
export type AddVideosToPlaylistMutationHookResult = ReturnType<
  typeof useAddVideosToPlaylistMutation
>
export type AddVideosToPlaylistMutationResult = ApolloReactCommon.MutationResult<
  AddVideosToPlaylistMutation
>
export type AddVideosToPlaylistMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddVideosToPlaylistMutation,
  AddVideosToPlaylistMutationVariables
>
